import React, { useContext, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import FavouriteButton from "../FavouriteButton";
import { type ICourse } from "../../lib/models/Course";
import Placeholder from "./../../assets/icons/light/placeholder_bg.svg";
import AddWatchList from "../Popups/AddWatchList";
import PopUpsContainer from "../../containers/PopUpsContainer";
import ProgressIndicator from "../ProgressIndicator";
import {
  convertToDateFormat,
  getTimeElapsedSince,
} from "../TimelineCourseCard";
import { getCurrentUserRole } from "../../lib/auth";
import { RoleContext } from "../../App";
import { gaEvent } from "../../lib/hooks/useGoogleAnalytics";

export interface ICourseProp {
  course: ICourse;
  cardType?: string;
  handleClick: (name: string) => void;
}

function CourseCard(props: ICourseProp): React.ReactElement {
  const {
    id,
    name,
    createdBy,
    imageUrl = "",
    isFavourite,
    isAccessible = true,
    enrolledAt,
    completedAt,
    progress = 0,
    isAuthorised,
  } = props.course;

  const [isOpen, setIsOpen] = useState(false);
  const closePopUp = (): void => {
    setIsOpen(false);
  };
  const { appMode } = useContext(RoleContext);
  const handleAddFavourite = (): void => {
    gaEvent("toggle", "Favourite", `Course: ${name}`);
  };
  return (
    <div
      className={`CourseCardWrapper ${
        props.cardType === "enrolled" ? "admin-enrolled" : ""
      }`}
    >
      <div className="CourseCard">
        {isOpen ? (
          <PopUpsContainer>
            <AddWatchList closePopUp={closePopUp} />
          </PopUpsContainer>
        ) : (
          ""
        )}
        <div className="course-img">
          <Link
            className="card-link"
            to={`/course/${id}`}
            onClick={() => {
              props.handleClick(name);
            }}
          >
            <img
              src={imageUrl}
              id="redirect-link"
              alt="Course Pic"
              referrerPolicy="no-referrer"
              onError={(e) => {
                (e.target as HTMLInputElement).onerror = null;
                (e.target as HTMLInputElement).src = Placeholder;
              }}
            />
          </Link>
        </div>
        <div className="course-details">
          <div className="header">
            <div className="name">
              <Link
                className="card-link"
                to={`/course/${id}`}
                onClick={() => {
                  props.handleClick(name);
                }}
              >
                {name}
              </Link>
            </div>
            {props.cardType !== "trainer" && (
              <div
                className={`progress ${
                  props.cardType === "dashboard" ? "progress-hide" : ""
                }`}
              >
                {enrolledAt !== undefined && (
                  <ProgressIndicator
                    progress={progress ?? 0}
                    isBackgroundWhite
                  />
                )}
              </div>
            )}
          </div>
          {props.cardType === "enrolled" || props.cardType === "trainer" ? (
            <div className="footer">
              <div className="start-date">
                {" "}
                {props.cardType === "trainer"
                  ? props.course.createdAt !== undefined &&
                    `Created : ${convertToDateFormat(props.course.createdAt)}`
                  : `Start Date - ${convertToDateFormat(enrolledAt ?? "")}`}
              </div>
              <div className="end-date">
                {" "}
                {props.cardType === "trainer"
                  ? props.course.updatedAt !== undefined &&
                    `Last Updated : ${getTimeElapsedSince(
                      props.course.updatedAt
                    )}`
                  : completedAt !== null &&
                    `End Date - ${convertToDateFormat(completedAt ?? "")}`}
              </div>
            </div>
          ) : (
            <div className="footer">
              <div className="author">By {createdBy.name}</div>
              <div className="icons">
                {/* <div
                  className="addToPlaylist"
                onClick={() => {
                  setIsOpen(true);
                }}
                >
                  {theme==="light"?
                <WatchListIcon
                />:<WatchListIconDark
                />
              }
                </div> */}
                <FavouriteButton
                  isLiked={isFavourite}
                  courseId={id}
                  handleAddFavourite={handleAddFavourite}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {((!isAccessible && getCurrentUserRole() === "STUDENT") ||
        (appMode === "TRAINER" && isAuthorised === false)) && (
        <div className="not-accessible">
          <div className="bottom-message">Not Accessible</div>
        </div>
      )}
    </div>
  );
}

export default CourseCard;
