import React, { Component, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { RoleContext } from "../../App";
import LoadingAnimation from "../../components/LoadingAnimation";
import WelcomeLeftPanel from "../../containers/WelcomeLeftPanel";
import WelcomeRightPanel from "../../containers/WelcomeRightPanel";
import {
  type ILoginResponse,
  getCurrentUserRole,
  getSessionStoredData,
  modifyAuthToken,
  setSessionStoredData,
} from "../../lib/auth";
import {
  getAllOrganizationsOfUser,
  type IOrganization,
  validateOrganizationId,
} from "../../lib/models/Organization";
import ChooseOrganization from "../../components/ChooseOrganization";
import { faro } from "@grafana/faro-web-sdk";

export default class ValidateAndJoinOrg extends Component {
  render(): React.ReactElement {
    return <ValidateAndJoinOrgPage />;
  }
}

export const ValidateAndJoinOrgPage: React.FunctionComponent<
  Record<string, unknown>
> = () => {
  const { setAppMode } = useContext(RoleContext);
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState({
    data: [] as IOrganization[],
    isLoading: false,
    error: "",
  });
  const [showContactAdminMessage, setShowContactAdminMessage] = useState(false);
  const [showSelectOrg, setShowSelectOrg] = useState(false);
  const showAllOrgPopUp = (): void => {
    getAllOrganizationsOfUser()
      .then((data) => {
        setOrganizations({
          ...organizations,
          data,
          isLoading: false,
        });
        if (data.length === 0) {
          setShowContactAdminMessage(true);
        } else if (data.length === 1) {
          validateOrganizationId(data[0].id)
            .then((res) => {
              setSessionStoredData({ key: "org_id", value: `${data[0].id}` });
              modifyAuthToken(res.accessToken);
              setAppMode(getCurrentUserRole());
              navigate("/dashboard");
            })
            .catch((err) => {
              window.location.href = "/logout";
              if (faro?.api?.pushError) {
                faro.api.pushError(err);
              }
            });
        } else {
          setShowSelectOrg(true);
        }
      })
      .catch((err) => {
        window.location.href = "/logout";
        if (faro?.api?.pushError) {
          faro.api.pushError(err);
        }
      });
  };

  useEffect(() => {
    // check if org_id is present in localstorage
    const orgId = +(getSessionStoredData("org_id") ?? "-1");

    if (orgId !== -1) {
      validateOrganizationId(orgId)
        .then((res: ILoginResponse) => {
          setSessionStoredData({ key: "org_id", value: `${orgId}` });
          modifyAuthToken(res.accessToken);
          setAppMode(getCurrentUserRole());
          navigate("/dashboard");
        })
        .catch((err) => {
          if (err.message === "Invalid org id") {
            showAllOrgPopUp();
          }
          if (faro?.api?.pushError) {
            faro.api.pushError(err);
          }
        });
    } else {
      // call get all org api
      showAllOrgPopUp();
    }
  }, []);

  return (
    <div className="validation-page welcome-page">
      <Helmet>
        <title>Validate Organization | Training and Upskilling</title>
        <meta name="description" content="Validate" />
      </Helmet>
      {() => {
        window.scrollTo(0, 0);
      }}
      <WelcomeLeftPanel />
      <WelcomeRightPanel>
        {showSelectOrg ? (
          <ChooseOrganization orgData={organizations.data} />
        ) : showContactAdminMessage ? (
          <div className="contact-us-wrapper">
            <div className="heading">Contact to Join</div>
            <div className="contact-message">
              <div>
                You are not part of any organization. Contact your organization
                admin.
              </div>
              {/* <p>
                If you wish to create an organization, mail to{" "}
                <a
                  href={`mailto:${superAdminEmail}?subject=Organization Application`}
                >
                  {superAdminEmail}
                </a>
              </p> */}
            </div>
            <button
              className="logout-btn remove-button"
              onClick={() => {
                navigate("/logout");
              }}
            >
              Logout
            </button>
          </div>
        ) : (
          <LoadingAnimation />
        )}
      </WelcomeRightPanel>
    </div>
  );
};
