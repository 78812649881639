import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { RoleContext } from "../../App";
import {
  checkIfUserisSuperAdmin,
  getCurrentOrganizationFromJWT,
  getCurrentUserRole,
  modifyAuthToken,
  setSessionStoredData,
} from "../../lib/auth";
import Placeholder from "./../../assets/icons/light/placeholder_bg.svg";
import Loader from "../Popups/Loader";
import PopUpsContainer from "../../containers/PopUpsContainer";
import {
  type IOrganization,
  getAllOrganizationsOfUser,
  validateOrganizationId,
} from "../../lib/models/Organization";
import LoadingAnimation from "../LoadingAnimation";
import ContactUsPopUp from "../Popups/ContactUs";
import { faro } from "@grafana/faro-web-sdk";

interface OrgDashboardDropDownProps {
  renderAgain: () => void;
  setOrgDashboardDropDown: (orgdashboardDropDown: boolean) => void;
}
function OrgDashboardDropDown({
  setOrgDashboardDropDown,
  renderAgain,
}: OrgDashboardDropDownProps): React.ReactElement {
  const navigate = useNavigate();
  const { appMode, setAppMode } = useContext(RoleContext);
  const ref = useRef() as React.RefObject<HTMLDivElement>;
  const [allOrganizations, setAllOrganizations] = useState({
    data: [] as IOrganization[],
    isLoading: true,
    error: "",
  });

  let isMounted = true;
  const handleClick = (e: MouseEvent): void => {
    if (
      (ref.current != null &&
        !ref.current.contains(e.target as HTMLDivElement)) ||
      (e.target as HTMLDivElement).className === "organization-card" ||
      (e.target as HTMLImageElement).className === "organization-image" ||
      (e.target as HTMLDivElement).className ===
        "organization-name limit-text-to-1-line"
    ) {
      setOrgDashboardDropDown(false);
    }
  };

  useEffect(() => {
    getAllOrganizationsOfUser()
      .then((data) => {
        setAllOrganizations({
          ...allOrganizations,
          data,
          isLoading: false,
        });
      })
      .catch((err) => {
        setAllOrganizations({
          ...allOrganizations,
          isLoading: false,
          error: err.message,
        });
        if (faro?.api?.pushError) {
          faro.api.pushError(err);
        }
      });
  }, []);

  useEffect(() => {
    if (isMounted) document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
      isMounted = false;
    };

    // return () => {
    //   isMounted = false;
    // };
  }, []);
  const [isLoaderModalOpen, setIsLoaderModalOpen] = useState(false);
  const [showContactUsPopUp, setShowContactUsPopUp] = useState(false);

  return (
    <div className="dropdown user-organization">
      {isLoaderModalOpen && (
        <PopUpsContainer>
          <Loader />
        </PopUpsContainer>
      )}
      {showContactUsPopUp && (
        <PopUpsContainer>
          <ContactUsPopUp
            closePopUp={() => {
              setShowContactUsPopUp(false);
            }}
          />
        </PopUpsContainer>
      )}
      {allOrganizations.isLoading ? (
        <div className="loading-wrapper">
          <LoadingAnimation />
        </div>
      ) : (
        <div className="demo-wrapper" ref={ref}>
          <div className="section current-org">
            <div className="section-head">Current Organization</div>
            <OrganizationCard
              organization={
                allOrganizations.data.filter(
                  (org) => org.id === getCurrentOrganizationFromJWT()
                )[0]
              }
              handleClick={() => {
                //
              }}
            />
          </div>
          {allOrganizations.data.length > 1 && (
            <div className="section switch-org">
              <div className="section-head">Switch Organization</div>
              <div className="all-organization-wrapper">
                {allOrganizations.data
                  .filter((org) => org.id !== getCurrentOrganizationFromJWT())
                  .map((org, index) => (
                    <OrganizationCard
                      key={index}
                      organization={org}
                      handleClick={(id) => {
                        setIsLoaderModalOpen(true);
                        validateOrganizationId(id)
                          .then((res) => {
                            setSessionStoredData({
                              key: "org_id",
                              value: `${id}`,
                            });
                            modifyAuthToken(res.accessToken);
                            setAppMode(getCurrentUserRole());
                            setOrgDashboardDropDown(false);
                            setIsLoaderModalOpen(false);
                            renderAgain();
                            if (window.location.pathname === "/dashboard") {
                              window.location.reload();
                            } else {
                              navigate("/dashboard");
                            }
                          })
                          .catch((err) => {
                            setIsLoaderModalOpen(false);
                            if (faro?.api?.pushError) {
                              faro.api.pushError(err);
                            }
                          });
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
          {appMode === "ADMIN" && (
            <>
              <Link
                className="dropdown-actions manage-organization"
                to={`/organization/${getCurrentOrganizationFromJWT()}`}
              >
                Manage Organization
              </Link>
              {checkIfUserisSuperAdmin() && (
                <Link
                  className="dropdown-actions all-organizations"
                  to={"/organizations/all"}
                >
                  All Organizations
                </Link>
              )}
              {checkIfUserisSuperAdmin() ? (
                <Link
                  className="dropdown-actions create-organization"
                  to={"/organization/new"}
                >
                  Create Organization
                </Link>
              ) : (
                <div
                  className="dropdown-actions create-organization"
                  onClick={() => {
                    setShowContactUsPopUp(true);
                  }}
                >
                  Create Organization
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

interface IOrganizationCard {
  organization: IOrganization;
  handleClick: (id: number) => void;
}

export function OrganizationCard({
  organization,
  handleClick,
}: IOrganizationCard): React.ReactElement {
  return (
    <div
      className="organization-card"
      onClick={() => {
        handleClick(organization.id);
      }}
    >
      <img
        className="organization-image"
        src={organization.logoUrl}
        alt="org-logo"
        referrerPolicy="no-referrer"
        onError={(e) => {
          (e.target as HTMLInputElement).onerror = null;
          (e.target as HTMLInputElement).src = Placeholder;
        }}
      />
      <div className="organization-name limit-text-to-1-line">
        {organization.name}
      </div>
    </div>
  );
}

export default OrgDashboardDropDown;
