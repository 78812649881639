import React, { type ReactElement } from "react";
import "./style.scss";

interface WelcomeRightPanelProps {
  children: ReactElement;
}

function WelcomeRightPanel(props: WelcomeRightPanelProps): React.ReactElement {
  return <div className="welcome-right-space">{props.children}</div>;
}

export default WelcomeRightPanel;
