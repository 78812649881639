import { faro } from "@grafana/faro-web-sdk";
import { BASE_URL } from "../constants";
import {
  type ILoginResponse,
  getStoredSessionData,
  getRefreshTokenFromSession,
  getCurrentOrganizationFromJWT,
  getCurrentUserRole,
  modifyAuthToken,
  clearStoredSession,
} from "./auth";

export interface IdataConfig {
  url: string;
  data?: Record<string, any>;
}
export interface IFileDataConfig {
  url: string;
  data?: XMLHttpRequestBodyInit | Document;
  progressBar?: HTMLDivElement;
  progressTextSpace?: HTMLDivElement;
}

function addSlash(str: string): string {
  const firstCharacter = str[0];
  if (firstCharacter === "/") return "";
  return "/";
}

function getToken(): string {
  if (
    getStoredSessionData().userData.authToken !== "" &&
    getStoredSessionData().userData.authToken !== undefined
  ) {
    return `Bearer ${getStoredSessionData().userData.authToken}`;
  }

  return getStoredSessionData().userData.idToken;
}

async function handleAPIResponse(response: Response): Promise<void> {
  switch (response.status) {
    case 404:
      window.location.href = "/404";
      break;
    case 403:
    case 401:
      clearStoredSession();
      window.location.href = "/logout";
      break;
    case 400: {
      const data = await response.json();
      throw new Error(data.errors[0].reason);
    }
    default: {
      const res = await response.json();
      throw new Error(res.errors[0].reason);
    }
  }
}

// Todo need to fix this`
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class Api {
  private static refreshInterval: NodeJS.Timeout | null = null; // To store the interval reference

  static cleanup(): null {
    if (Api.refreshInterval !== null) {
      clearInterval(Api.refreshInterval);
    }
    return null;
  }

  private static refreshPromise: Promise<void> | null = null;

  static async refreshToken(): Promise<void> {
    if (Api.refreshPromise !== null) {
      // If a refresh is already in progress, wait for it to complete
      await Api.refreshPromise;
      return;
    }

    if (!getRefreshTokenFromSession()) {
      return; // Return void since it's already async
    }

    Api.refreshPromise = new Promise((resolve, reject) => {
      const body = {
        organizationId: getCurrentOrganizationFromJWT(),
        currentRole: getCurrentUserRole().toLocaleLowerCase(),
      };

      Api.updateAuthToken({
        url: "/login/refresh",
        data: { ...body },
      })
        .then((data) => {
          modifyAuthToken(data.accessToken);
          resolve();
        })
        .catch((error) => {
          clearStoredSession();
          window.location.href = "/logout";
          reject(error);
          if (faro?.api?.pushError) {
            faro.api.pushError(error);
          }
        })
        .finally(() => {
          Api.refreshPromise = null; // Reset the refreshPromise
        });
    });

    await Api.refreshPromise;
  }

  // Function to start the refresh token interval
  static startRefreshTokenInterval(): void {
    Api.clearRefreshTokenInterval();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    Api.refreshInterval = setInterval(async () => {
      try {
        await Api.refreshToken();
      } catch (error) {
        if (faro?.api?.pushError) {
          if (error instanceof Error) {
            faro.api.pushError(error);
          }
        }
      }
    }, 270000);
  }

  static clearRefreshTokenInterval(): void {
    if (Api.refreshInterval !== null) {
      clearInterval(Api.refreshInterval);
      Api.refreshInterval = null;
    }
  }

  static getApiUrl(): string {
    return BASE_URL;
  }

  // to do figuring out the return type of this
  static async get(config: IdataConfig, ct = 1): Promise<any> {
    const params = config.data ?? {};
    const url: URL = new URL(
      Api.getApiUrl() + addSlash(config.url) + config.url
    );
    Object.keys(params).forEach((key) => {
      url.searchParams.append(key, params[key]);
    });

    const initParams = {
      headers: {
        Authorization: `${getToken()}`,
      },
    };
    return await fetch(url.toString(), initParams).then(async (data) => {
      if (data.ok) {
        return await data.json().then((value1) => value1.data);
      } else if (data.status === 401 && ct === 1) {
        await Api.refreshToken();
        return await Api.get(config, ct + 1); // Retry the original request
      } else {
        void handleAPIResponse(data);
      }
    });
  }

  static async post(config: IdataConfig): Promise<any> {
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${getToken()}`,
      },
      body: JSON.stringify(config.data),
    };

    return await fetch(
      Api.getApiUrl() + addSlash(config.url) + config.url,
      params
    ).then(async (response) => {
      if (response.ok) {
        return await response.json().then((value) => value.data);
      } else if (response.status === 401) {
        await Api.refreshToken();
        return await Api.post(config); // Retry the original request
      } else {
        await handleAPIResponse(response);
      }
    });
  }

  static async put(config: IdataConfig): Promise<any> {
    const params = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${getToken()}`,
      },
      body: JSON.stringify(config.data),
    };

    return await fetch(
      Api.getApiUrl() + addSlash(config.url) + config.url,
      params
    ).then(async (response) => {
      if (response.ok) {
        return await response.json().then((value) => value.data);
      } else if (response.status === 401) {
        await Api.refreshToken();
        return await Api.post(config); // Retry the original request
      } else {
        void handleAPIResponse(response);
      }
    });
  }

  static async patch(config: IdataConfig): Promise<any> {
    const params = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${getToken()}`,
        refreshToken: `${getRefreshTokenFromSession()}`,
      },
      body: JSON.stringify(config.data),
    };

    return await fetch(
      Api.getApiUrl() + addSlash(config.url) + config.url,
      params
    ).then(async (response) => {
      if (response.ok) {
        return await response.json().then((value) => value.data);
      } else if (response.status === 401) {
        await Api.refreshToken();
        return await Api.post(config); // Retry the original request
      } else {
        void handleAPIResponse(response);
      }
    });
  }

  static async delete(config: IdataConfig): Promise<any> {
    const params = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${getToken()}`,
        refreshToken: `${getRefreshTokenFromSession()}`,
      },
      body: JSON.stringify(config.data),
    };

    return await fetch(
      Api.getApiUrl() + addSlash(config.url) + config.url,
      params
    ).then(async (response) => {
      if (response.ok) {
        if (response.status === 204 || response.status === 201) {
          // Handle 204 response (No Content) here
          return null;
        } else {
          return await response.json().then((value) => value.data);
        }
      } else if (response.status === 401) {
        await Api.refreshToken();
        return await Api.delete(config);
      } else {
        void handleAPIResponse(response);
      }
    });
  }

  static async postFile(config: IFileDataConfig): Promise<any> {
    return await new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open("POST", config.url);
      request.setRequestHeader("Accept", "application/json");
      request.setRequestHeader(
        "Developer-Token",
        "7b172834-1703-4169-b16a-18e9bfabd587"
      );
      request.upload.addEventListener("progress", function (e) {
        const percentageUploaded = Math.floor((e.loaded / e.total) * 90);
        if (config.progressBar != null)
          config.progressBar.style.width = `${percentageUploaded}%`;
        if (config.progressTextSpace != null)
          config.progressTextSpace.innerHTML = `${percentageUploaded}%`;
      });
      request.addEventListener("load", function () {
        if (request.status >= 200 && request.status < 300) {
          if (config.progressBar != null)
            config.progressBar.style.width = "100%";
          if (config.progressTextSpace != null)
            config.progressTextSpace.innerHTML = "100%";
          const urlObject = JSON.parse(request.response);
          resolve(urlObject.data.url ?? urlObject.data.imageUpload.url);
        } else {
          switch (request.status) {
            case 404:
              window.location.href = "/404";
              break;
            case 403:
              window.location.href = "/404";
              break;
            default:
              throw new Error(request.statusText);
          }
          reject(request.status.toString());
        }
      });
      request.send(config.data);
    });
  }

  static async orgGet(config: IdataConfig): Promise<any> {
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        refreshToken: `${getRefreshTokenFromSession()}`,
      },
      body: JSON.stringify(config.data),
    };

    return await fetch(
      Api.getApiUrl() + addSlash(config.url) + config.url,
      params
    ).then(async (data) => {
      if (data.ok) {
        return await data.json().then((value) => value.data);
      } else {
        switch (data.status) {
          case 403:
            throw new Error("Invalid org id");
          case 400:
            window.location.href = "/logout";
            break;
          default:
            // window.alert((await data.json()).errors[0].reason);
            throw new Error((await data.json()).errors[0].reason);
        }
      }
    });
  }

  static async updateAuthToken(config: IdataConfig): Promise<ILoginResponse> {
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        refreshToken: `${getRefreshTokenFromSession()}`,
      },
      body: JSON.stringify(config.data),
    };

    return await fetch(
      Api.getApiUrl() + addSlash(config.url) + config.url,
      params
    ).then(async (response) => {
      if (response.ok) {
        return await response.json().then((value) => value.data);
      } else {
        window.location.href = "/logout";
      }
    });
  }

  /* 
  this function used to upload the file from the 
  backend in case file not uploading from the frontend itself
  do not remove this it will come in handy when zopping media throws CORS error
   */
  static async upload(config: { url: string; data: FormData }): Promise<any> {
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `${getToken()}`,
      },
      body: config.data,
    };
    return await fetch(
      Api.getApiUrl() + addSlash(config.url) + config.url,
      params
    ).then(async (response) => {
      if (response.ok) {
        return await response.json().then((value) => value.data);
      } else void handleAPIResponse(response);
    });
  }
}

Api.startRefreshTokenInterval();
export default Api;
