import React from "react";
import { type IStreamAdmin } from "../../lib/models/Stream";
import "./style.scss";
import { streamCardsBackgroundColors } from "../../constants";
import { Link } from "react-router-dom";

interface IStreamCardProp {
  stream: IStreamAdmin;
  index: number;
  handleClick: (name: string) => void;
}

function StreamCard({
  stream,
  index,
  handleClick,
}: IStreamCardProp): React.ReactElement {
  const { streamName, streamId } = stream;
  const paddingOnCard = {
    paddingTopCard: [
      "0px",
      { 0: "236px", 1: "180px", 2: "101px", 3: "185px" }[index % 4],
      { 0: "56px", 1: "8px", 3: "8px", 4: "8px" }[index % 4],
      { 0: "0px", 1: "0px", 2: "39px", 3: "0px" }[index % 4],
    ],
    paddingBottomCard: [
      { 0: "8px", 1: "8px", 2: "8px", 3: "30px" }[index % 4],
      { 0: "185px", 1: "185px", 2: "185px", 3: "95px" }[index % 4],
    ],
  };
  const marginOnCard = {
    marginTopCard: [
      { 0: "-80px", 1: "-50px", 2: "-70px", 3: "-50px" }[index % 4],
      { 0: "-80px", 1: "-50px", 2: "30px", 3: "-50px" }[index % 4],
    ],
  };
  return (
    <Link
      to={`/stream/${streamId}`}
      className="admin-Stream-card"
      style={{ backgroundImage: streamCardsBackgroundColors[index % 4] }}
      onClick={() => {
        handleClick(streamName);
      }}
    >
      <div className="stream-card-background">
        <div
          className="background-top"
          style={{
            paddingTop: paddingOnCard.paddingTopCard[0],
            paddingRight: paddingOnCard.paddingTopCard[1],
            paddingBottom: paddingOnCard.paddingTopCard[2],
            paddingLeft: paddingOnCard.paddingTopCard[3],
          }}
        >
          <div
            className="circle"
            style={{
              marginTop: marginOnCard.marginTopCard[0],
              marginLeft: marginOnCard.marginTopCard[1],
            }}
          ></div>
        </div>
        <div
          className="background-bottom"
          style={{
            paddingTop: paddingOnCard.paddingBottomCard[0],
            paddingLeft: paddingOnCard.paddingBottomCard[1],
          }}
        >
          <div className="circle"></div>
        </div>
      </div>
      <div className="stream-card-foreground">
        <div className="stream-name">{streamName}</div>
      </div>
    </Link>
  );
}

export default StreamCard;
