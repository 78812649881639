import React, { type ReactElement, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import { ThemeContext } from "../../context/ThemeContext";
import "./style.scss";

interface PopUpsContainerProps {
  children: ReactElement;
}

function PopUpsContainer(props: PopUpsContainerProps): React.ReactElement {
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    return () => {
      document.getElementsByTagName("body")[0].style.overflow = "scroll";
    };
  }, []);
  return createPortal(
    <div className={theme}>
      <div className="background-disable"></div>
      <div className="popup">{props.children}</div>
    </div>,
    document.getElementById("portal") as Element
  );
}

export default PopUpsContainer;
