import Api from "../api";
import { type IChapter } from "./Chapter";
import {
  ConvertAdminGetCourseResponse,
  ConvertStudentGetCourseResponse,
} from "./Convert";
import { type IQuiz } from "./Quiz";
import { type ICollaborator } from "./Trainer";

export interface ICourseDetailsStudent {
  id: number;
  name: string;
  description: string;
  isAccessible: boolean;
  imageUrl: string;
  createdBy: ICollaborator;
  isFavourite: boolean;
  about: string;
  isEnrolled: boolean;
  createdAt: string;
  completedAt: string;
  updatedAt: string;
  level: string;
  noOfChapters: number;
  noOfResources: number;
  noOfEnrollments: number;
  progress?: number;
}

export interface ICourseDetailsStudentApiResponse {
  id: number;
  name: string;
  description: string;
  isAccessible: boolean;
  imageUrl: string;
  createdBy: ICollaborator;
  isFavourite: boolean;
  about: string;
  isEnrolled: boolean;
  createdAt: string;
  completedAt: string;
  updatedAt: string;
  level: string;
  noOfChapters: number;
  noOfResources: number;
  noOfEnrollments: number;
  progress?: number;
}

export interface ICourseDetailsAdmin {
  courseId: number;
  courseName: string;
  imageUrl: string;
  isAccessible: boolean;
  isOwner: boolean;
  description: string;
  about: string;
  level: string;
  prerequisites: string[];
  createdBy: ICollaborator;
  createdAt: string;
  updatedAt: string;
  collaborators: ICollaborator[];
}

export interface ICourseDetailsAdminApiResponse {
  courseId: number;
  courseName: string;
  imageUrl: string;
  isAccessible: boolean;
  isOwner: boolean;
  description: string;
  about: string;
  level: string;
  prerequisites: string[];
  createdBy: ICollaborator;
  createdAt: string;
  updatedAt: string;
  collaborators: ICollaborator[];
}

export interface IEnrolledCourseResponse {
  completedAt: string;
  courseId: number;
  courseName: string;
  createdBy: ICollaborator;
  enrolledAt: string;
  imageUrl: string;
  isFavourite: boolean;
  isAccessible: boolean;
  progress: number;
  isAuthorised?: boolean;
}

export interface IEnrolledCourseStatsResponse {
  enrolledCourses: IEnrolledCourseResponse[];
  averageProgress: number;
  count: number;
}

export interface IFavouriteCourseResponse {
  completedAt: string;
  id: number;
  name: string;
  createdBy: ICollaborator;
  enrolledAt: string;
  imageUrl: string;
  isFavourite: boolean;
  isAccessible: boolean;
}

export interface ICoursePostRequest {
  about: string;
  contentLink: string;
  description: string;
  imageUrl: string;
  isAccessible: boolean;
  level: string;
  name: string;
  prerequisites: string[];
  quizzes?: IQuiz[];
  resources?: IResourceInCourse[];
  collaboratorIds?: number[];
  collaboratorEmailIds?: string[];
  isOwner?: boolean;
  createdBy?: ICollaborator;
}

export interface ICourseUpdateRequest {
  about?: string;
  contentLink?: string;
  description?: string;
  imageUrl?: string;
  isAccessible?: boolean;
  level?: string;
  name?: string;
  prerequisites?: string[];
  quizzes?: IQuiz[];
  resources?: IResourceInCourse[];
  chapters?: IChapter[];
  collaboratorIds?: number[];
  collaboratorEmailIds?: string[];
}

export interface IResourceInCourse {
  id?: number;
  resourceName: string;
  resourceLink?: string;
  resourceType?: "FILE" | "LINK" | "TEXT";
  isCompleted?: boolean;
  isVisited?: boolean;
}

export interface ICourseProgessInPath {
  id: number;
  name: string;
  imageUrl: string;
  enrolledAt: string;
  completedAt: string;
  progress: number;
}

// GET COURSE BY ID

export const getCourseById = async (
  id: number
): Promise<ICourseDetailsStudent> => {
  const courseData: ICourseDetailsStudentApiResponse = await Api.get({
    url: `/students/courses/${id}`,
    data: {},
  });

  return ConvertStudentGetCourseResponse(courseData);
};

// Get course By Id Admin
export const getAdminCourseById = async (
  id: number
): Promise<ICourseDetailsAdmin> => {
  const AdminCourseApiResponse: ICourseDetailsAdminApiResponse = await Api.get({
    url: `/admin/courses/${id}`,
    data: {},
  });
  return ConvertAdminGetCourseResponse(AdminCourseApiResponse);
};

// Like course by ID

export const likeCourseById = async (id: number): Promise<void> => {
  await Api.post({
    url: "/student/favourites",
    data: {
      courseId: id,
    },
  });
};

// Dislike course by ID

export const dislikeCourseById = async (id: number): Promise<void> => {
  return await Api.delete({
    url: `student/favourites/${id}`,
    data: {},
  });
};

// POST COURSE ENROLLED BY ID
export const enrollInCourseByCourseId = async (id: number): Promise<void> => {
  return await Api.post({
    url: "/students/course-enrollment",
    data: {
      courseId: +id,
    },
  });
};

// POST COURSE COMPLETED BY ID
export const completeCourseByCourseId = async (id: number): Promise<void> => {
  return await Api.patch({
    url: `/students/course-complete/${id}`,
    data: {},
  });
};

// POST COURSE CREATION
export const createCourse = async (
  data: ICoursePostRequest
): Promise<{ courseId: number }> => {
  return await Api.post({
    url: "/admin/courses",
    data,
  });
};

// Update COURSE by ID

export const updateCourseById = async (
  id: number,
  data: ICourseUpdateRequest
): Promise<void> => {
  return await Api.patch({
    url: `/admin/courses/${id}`,
    data,
  });
};
/**
 * this function will fetch the file data
 * @param fileUrl url of the file
 * @returns return promise of html in the form of string
 */

export const getTextData = async (fileUrl: string): Promise<string> => {
  return await Api.get({
    url: "read/file",
    data: {
      fileUrl,
    },
  });
};

// REMOVE COURSE BY ID

export const removeCourseById = async (id: number): Promise<void> => {
  return await Api.delete({
    url: `/admin/courses/${id}`,
    data: {},
  });
};

// UPLOAD IMAGES AND FILES TO ZOPPING.

export const saveFile = async (
  file: File,
  progressBar?: HTMLDivElement,
  progressTextSpace?: HTMLDivElement
): Promise<string> => {
  const form = new FormData();
  const FileIsAnImage = (): boolean => {
    return file.type.includes("image");
  };
  if (FileIsAnImage()) {
    form.append("imageUpload", file);
    return await Api.postFile({
      url: "https://zopping.com/api/media-service/image-upload",
      data: form,
      progressTextSpace,
      progressBar,
    });
  }
  form.append("fileUpload", file);
  return await Api.postFile({
    url: "https://zopping.com/api/media-service/file",
    data: form,
    progressTextSpace,
    progressBar,
  });
};

// No of enrolled courses of a student

export const getCourseEnrollmentsCount = async (): Promise<number> => {
  return await Api.get({
    url: "students/no-of-enrolled-courses",
    data: {},
  });
};

export const getResourcesOfCourse = async (
  courseId: number
): Promise<IResourceInCourse[]> => {
  return await Api.get({
    url: `admin/courses/${courseId}/resources`,
    data: {},
  });
};

export const getStudentsResourcesOfCourse = async (
  courseId: number
): Promise<IResourceInCourse[]> => {
  return await Api.get({
    url: `students/courses/${courseId}/resources`,
    data: {},
  });
};
