import React from "react";
import { ReactComponent as AppLoginLogo } from "../../assets/icons/light/skilluplogin.svg";
import { ReactComponent as LoginGraphic } from "../../assets/icons/light/logoo.svg";

import "./style.scss";

function WelcomeLeftPanel(): React.ReactElement {
  return (
    <div className="welcome-design">
      <div className="app-name">
        <AppLoginLogo />
      </div>
      <div className="app-image-icon">
        <LoginGraphic className="app-image" />
      </div>
    </div>
  );
}

export default WelcomeLeftPanel;
