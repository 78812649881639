import React, { createContext, useState } from "react";
import { getTheme, setSessionStoredData } from "../lib/auth";

export interface IThemeContext {
  theme: string;
  updateTheme: () => void;
}

export const ThemeContext = createContext<IThemeContext>({
  theme: getTheme() === "" ? "light" : getTheme(),
  updateTheme: () => {},
});

export const ThemeProvider = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const [theme, setTheme] = useState(getTheme());
  const updateTheme = (): void => {
    setTheme(theme === "light" ? "dark" : "light");
    setSessionStoredData({
      key: "theme",
      value: `${getTheme() === "light" ? "dark" : "light"}`,
    });
  };
  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
