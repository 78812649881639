import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import "./style.scss";

export interface IProgressIndicator {
  progress: number;
  isBackgroundWhite?: boolean;
}

function ProgressIndicator({
  progress,
  isBackgroundWhite = false,
}: IProgressIndicator): React.ReactElement {
  const { theme } = useContext(ThemeContext);
  const pageBackground = `${theme === "light" ? "#fff" : "#222222"}`;
  const appBackground = `${theme === "light" ? "#f9f9f9" : "#1c1d1f"}`;
  return (
    <div className="ProgressIndicator">
      <div
        className="inner"
        style={{
          backgroundColor: isBackgroundWhite ? pageBackground : appBackground,
        }}
      ></div>
      <div className="number">{progress}%</div>
      <div className="circle">
        <div
          className="bar left"
          style={{
            background: isBackgroundWhite ? appBackground : pageBackground,
          }}
        >
          <div
            id="progress-left"
            className="progress"
            style={{
              transform: `rotate(${3.6 * (progress > 50 ? 50 : progress)}deg)`,
            }}
          ></div>
        </div>
        <div
          className="bar right"
          style={{
            background: isBackgroundWhite ? appBackground : pageBackground,
          }}
        >
          <div
            id="progress-right"
            className={`progress ${progress < 50 ? "inactive" : ""}`}
            style={{ transform: `rotate(${3.6 * (progress - 50)}deg)` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ProgressIndicator;
