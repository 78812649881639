import React, { useContext, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { RoleContext } from "../../App";
import {
  getCurrentUserRole,
  modifyAuthToken,
  setSessionStoredData,
} from "../../lib/auth";
import { ReactComponent as SearchIcon } from "./../../assets/icons/light/searchicon.svg";
import {
  type IOrganization,
  validateOrganizationId,
} from "../../lib/models/Organization";
import OrganizationList from "../../containers/OrganizationList";
import { faro } from "@grafana/faro-web-sdk";

interface QuizProps {
  orgData: IOrganization[];
}

function ChooseOrganization({ orgData }: QuizProps): React.ReactElement {
  const navigate = useNavigate();
  const { setAppMode } = useContext(RoleContext);
  const [searchData, setSearchData] = useState(orgData);

  const handleFilter = (val: string): void => {
    if (val === "") {
      setSearchData(orgData);
    } else {
      setSearchData(
        orgData.filter((item) =>
          item.name.toLowerCase().includes(val.toLowerCase())
        )
      );
    }
  };

  const handleFormSubmission = (orgId: number): void => {
    validateOrganizationId(orgId)
      .then((res) => {
        setSessionStoredData({ key: "org_id", value: `${orgId}` });
        modifyAuthToken(res.accessToken);
        setAppMode(getCurrentUserRole());
        navigate("/dashboard");
      })
      .catch((err) => {
        if (faro?.api?.pushError) {
          faro.api.pushError(err);
        }
        localStorage.clear();
        window.location.href = "/logout";
      });
  };

  return (
    <div className="choose-organization">
      <div className="heading">Choose Organization</div>
      <div className="choose-org-container">
        <div className="search-org-container">
          <input
            className="search-org"
            type="text"
            id="search-org-input"
            placeholder="Search organization..."
            onChange={(e) => {
              handleFilter(e.currentTarget.value.trim());
            }}
          />
          <div
            className="search-icon-wrapper"
            onClick={() => {
              handleFilter(
                (
                  document.getElementById(
                    "search-org-input"
                  ) as HTMLInputElement
                ).value.trim()
              );
            }}
          >
            <SearchIcon className="search-org-icon" />
          </div>
        </div>
        <OrganizationList
          orgData={searchData}
          handleClick={handleFormSubmission}
        />
      </div>
    </div>
  );
}

export default ChooseOrganization;
