import React from "react";
import { superAdminEmail } from "../../../constants";
import "./style.scss";

function ContactUsPopUp({
  closePopUp,
}: {
  closePopUp: () => void;
}): React.ReactElement {
  return (
    <div className="ContactUs">
      <div className="contactus-message-wrapper">
        <div className="contact-message">
          <p>
            If you wish to create an organization, mail to{" "}
            <a
              href={`mailto:${superAdminEmail}?subject=Organization Application`}
            >
              {superAdminEmail}
            </a>
          </p>
        </div>
      </div>
      <div className="ok-button-wrapper">
        <button
          className="popup-control-button popup-update-button"
          onClick={() => {
            closePopUp();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default ContactUsPopUp;
