import React, { useContext } from "react";
import { type ICourseInPathResponse } from "../../lib/models/PathDescription";
import "./style.scss";
import { ThemeContext } from "../../context/ThemeContext";
import CourseCardInPathPage from "../CourseInPath";
import { convertICourseinPathResponsetoICourseAdmin } from "../../lib/models/Convert";
import { type IPathinBatch } from "../../lib/models/BatchDescription";

export interface IPathCoursesCard {
  course?: ICourseInPathResponse;
  isPathEnrolled?: boolean;
  path?: IPathinBatch;
}

function TimelineCourseCard({
  course,
  isPathEnrolled,
  path,
}: IPathCoursesCard): React.ReactElement {
  const { theme } = useContext(ThemeContext);
  const leftTimelinePendingColor = `${
    theme === "light" ? "#e5e5e5" : "#010101"
  }`;
  const leftTimelineCompleteColor = `${
    theme === "light" ? "#5624d0" : "#986eff"
  }`;
  const progress = (path ?? course)?.progress ?? 0;
  return (
    <div className="TimelineCourseCard">
      <div className="left-timeline">
        <div
          className="timeline-circle"
          style={{
            backgroundColor: `${
              progress > 0 && isPathEnrolled === true
                ? leftTimelineCompleteColor
                : leftTimelinePendingColor
            }`,
          }}
        ></div>
        <div
          className="timeline-line"
          style={{
            backgroundColor: `${leftTimelinePendingColor}`,
          }}
        >
          <div
            className="child"
            style={{
              backgroundColor: `${leftTimelineCompleteColor}`,
              height: `${progress}%`,
              width: "100%",
            }}
          ></div>
        </div>
      </div>
      <div className="right-card">
        {course !== undefined ? (
          <CourseCardInPathPage
            course={convertICourseinPathResponsetoICourseAdmin(course)}
          />
        ) : (
          <CourseCardInPathPage path={path} />
        )}
      </div>
    </div>
  );
}

export function convertToDateFormat(date: string | null): string {
  if (date === null || date === "") {
    return "-";
  }
  const myDate = new Date(date);
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${myDate.getDate()} ${
    month[myDate.getUTCMonth()]
  } ${myDate.getFullYear()}`;
}

export function getTimeElapsedSince(date: string | null): string {
  if (date === null || date === "") {
    return "-";
  } else {
    const currDate = new Date().getTime();
    const prevDate = new Date(date).getTime();
    const timeDiff = currDate - prevDate;

    const seconds = Math.floor(timeDiff / 1000);

    let interval = seconds / 86400;

    if (interval > 1) {
      if (interval >= 7) {
        return convertToDateFormat(date);
      } else {
        return (
          `${Math.floor(interval)}` +
          ` day${Math.floor(interval) !== 1 ? "s" : ""} ago`
        );
      }
    }

    // hour
    interval = seconds / 3600;
    if (interval > 1) {
      return (
        `${Math.floor(interval)}` +
        ` hour${Math.floor(interval) !== 1 ? "s" : ""} ago`
      );
    }

    // minute
    interval = seconds / 60;
    if (interval > 1) {
      return (
        `${Math.floor(interval)}` +
        ` minute${Math.floor(interval) !== 1 ? "s" : ""} ago`
      );
    }
    return (
      `${Math.floor(seconds)}` +
      ` second${Math.floor(interval) !== 1 ? "s" : ""} ago`
    );
  }
}

export default TimelineCourseCard;
