import React from "react";
import "./style.scss";

interface WatchListProps {
  closePopUp: () => void;
}
function AddWatchList(props: WatchListProps): React.ReactElement {
  return (
    <div className="AddWatchList">
      hello
      <button
        onClick={() => {
          props.closePopUp();
        }}
      >
        Close
      </button>
    </div>
  );
}

export default AddWatchList;
