import React from "react";
import "./style.scss";
import { type IOrganization } from "../../lib/models/Organization";
import Placeholder from "./../../assets/icons/light/placeholder_bg.svg";
interface IOrganizationList {
  orgData: IOrganization[];
  handleClick: (id: number) => void;
}

function OrganizationList({
  orgData,
  handleClick,
}: IOrganizationList): React.ReactElement {
  return (
    <div className="org-list-container">
      {orgData.map((org, idx) => (
        <div
          key={idx}
          className="org-card"
          onClick={() => {
            handleClick(org.id);
          }}
        >
          <img
            className="org-image"
            src={org.logoUrl}
            alt="org-logo"
            referrerPolicy="no-referrer"
            onError={(e) => {
              (e.target as HTMLInputElement).onerror = null;
              (e.target as HTMLInputElement).src = Placeholder;
            }}
          />
          <div className="org-name limit-text-to-1-line">{org.name}</div>
        </div>
      ))}
      {orgData.length === 0 && <h4>No organization found..</h4>}
    </div>
  );
}

export default OrganizationList;
