import Api from "../api";
import { type ILoginResponse } from "../auth";
import { type IStudent } from "./Student";

export interface IOrganizationPostBody {
  name: string;
  logoUrl: string;
  ownerEmail: string;
}

export interface IOrganization {
  id: number;
  name: string;
  logoUrl: string;
  createdAt: string;
  createdBy: IStudent;
}

export interface IAddUserData {
  userIds: number[];
  emailIds: string[];
}

export interface IRemoveUsers {
  ids: number[];
}

export const getAllOrganizationsOfUser = async (): Promise<IOrganization[]> => {
  return await Api.get({
    url: "/organizations",
  });
};

export const createOrganization = async (
  orgBody: IOrganizationPostBody
): Promise<{ id: number }> => {
  return await Api.post({
    url: "/organizations",
    data: orgBody,
  });
};

export const deleteOrganization = async (
  orgId: number
): Promise<{ id: number }> => {
  return await Api.delete({
    url: `/organizations/${orgId}`,
  });
};

export const updateOrganization = async (
  orgBody: IOrganizationPostBody,
  organizationId: number
): Promise<{ id: number }> => {
  return await Api.patch({
    url: `/organizations/${organizationId}`,
    data: orgBody,
  });
};

export const validateOrganizationName = async (
  orgName: string,
  organizationId?: number
): Promise<boolean> => {
  const validationResponse =
    organizationId !== undefined
      ? await Api.get({
          url: `/organizations/${orgName}/validate-name`,
          data: {
            organizationId,
          },
        })
      : await Api.get({
          url: `/organizations/${orgName}/validate-name`,
          data: {},
        });
  return validationResponse.isNameAvailableOrNot;
};

// currently not in use, will update it when it is used
export const joinOrganization = async (orgId: string): Promise<any> => {
  return await Api.post({
    url: `/organizations/${orgId}/join-organization`,
  });
};

export const validateOrganizationId = async (
  orgId: number
): Promise<ILoginResponse> => {
  return await Api.orgGet({
    url: `login/organization/${orgId}/validate`,
  });
};

export const getCurrentOrganizationOfUser =
  async (): Promise<IOrganization> => {
    return await Api.get({
      url: "users/organizations/current",
    });
  };

export const getOrganizationByID = async (
  organizationId: number
): Promise<IOrganization> => {
  return await Api.get({
    url: `organizations/${organizationId}`,
  });
};

export const getAllOrganizationsSuperAdmin = async (
  pageSize?: number,
  pageNo?: number
): Promise<IOrganization[]> => {
  return await Api.get({
    url: "/organizations/all",
    data: {
      pageSize: pageSize ?? 10,
      pageNo: pageNo ?? 1,
    },
  });
};

export const searchAllOrganizationsSuperAdmin = async (
  text: string,
  pageSize?: number,
  pageNo?: number
): Promise<IOrganization[]> => {
  return await Api.get({
    url: "/search/organizations",
    data: {
      pageSize: pageSize ?? 20,
      pageNo: pageNo ?? 1,
      title: text,
    },
  });
};

export const addAdminsInOrganization = async (
  userData: IAddUserData
): Promise<void> => {
  return await Api.post({
    url: "/admin",
    data: { ids: userData.userIds, emails: userData.emailIds },
  });
};

export const removeAdminsFromOrganization = async (
  idsToRemove: IRemoveUsers
): Promise<void> => {
  return await Api.delete({
    url: "/admin",
    data: idsToRemove,
  });
};

export const addTrainersInOrganization = async (
  userData: IAddUserData
): Promise<void> => {
  return await Api.post({
    url: "/trainers",
    data: { ids: userData.userIds, emails: userData.emailIds },
  });
};

export const removeTrainersFromOrganization = async (
  idsToRemove: IRemoveUsers
): Promise<void> => {
  return await Api.delete({
    url: "/trainers",
    data: idsToRemove,
  });
};

export const getEligibleAdminsOfOrganization = async (): Promise<
  IStudent[]
> => {
  return await Api.get({
    url: "/users/eligible-admins",
    data: {},
  });
};

export const getEligibleTrainersOfOrganization = async (): Promise<
  IStudent[]
> => {
  return await Api.get({
    url: "/users/eligible-trainers",
    data: {},
  });
};
