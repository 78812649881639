import React from "react";
import LoadingAnimation from "../../LoadingAnimation";

function Loader(): React.ReactElement {
  return (
    <div className="Loader">
      <LoadingAnimation />
    </div>
  );
}

export default Loader;
