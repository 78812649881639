import React, { useContext, useState } from "react";
import { ReactComponent as FavouriteDarkIcon } from "./../../assets/icons/dark/wishlist-outline.svg";
import { ReactComponent as FavouriteIcon } from "./../../assets/icons/light/wishlist-outline.svg";
import { ReactComponent as LikedDarkIcon } from "./../../assets/icons/dark/wishlist-filled.svg";
import { ReactComponent as FavoriteFilledIcon } from "../../assets/icons/light/wishlisticondark.svg";
import { ReactComponent as LikedIcon } from "./../../assets/icons/light/wishlist-filled.svg";
import "./style.scss";
import {
  dislikeCourseById,
  likeCourseById,
} from "../../lib/models/CourseDescription";
import { ThemeContext } from "../../context/ThemeContext";
import { faro } from "@grafana/faro-web-sdk";

interface IFavouriteButtonProps {
  isLiked: boolean;
  courseId: number;
  pageType?: string;
  arrIdx?: number;
  handleAddFavourite: () => void;
  removeCourse?: (idx: number) => void;
  useFilled?: boolean;
}
function FavouriteButton(props: IFavouriteButtonProps): React.ReactElement {
  const { isLiked, courseId, pageType, handleAddFavourite, useFilled } = props;
  const [likeButton, setLikeButton] = useState(isLiked);
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className="FavouriteButton"
      onClick={() => {
        handleAddFavourite();
        if (likeButton) {
          dislikeCourseById(courseId)
            .then(() => {
              if (
                pageType === "Favourite" &&
                props.removeCourse != null &&
                props.arrIdx !== undefined
              ) {
                props.removeCourse(props.arrIdx);
              }
            })
            .catch((err) => {
              window.alert(err);
              if (faro?.api?.pushError) {
                faro.api.pushError(err);
              }
            });
        } else {
          likeCourseById(courseId)
            .then(() => {
              if (pageType === "Favourite") window.location.reload();
            })
            .catch((err) => {
              window.alert(err);
              if (faro?.api?.pushError) {
                faro.api.pushError(err);
              }
            });
        }
        if (pageType !== "Favourite")
          setLikeButton((likeButton) => !likeButton);
      }}
    >
      {theme === "light" ? (
        likeButton ? (
          <LikedIcon />
        ) : (
          <>
            {useFilled !== undefined && useFilled ? (
              <FavoriteFilledIcon />
            ) : (
              <FavouriteIcon />
            )}
          </>
        )
      ) : likeButton ? (
        <LikedDarkIcon />
      ) : (
        <>
          {useFilled !== undefined && useFilled ? (
            <FavoriteFilledIcon />
          ) : (
            <FavouriteDarkIcon />
          )}
        </>
      )}
    </div>
  );
}

export default FavouriteButton;
