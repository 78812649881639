// @ts-nocheck

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearStoredSession } from "../lib/auth";
import { faro } from "@grafana/faro-web-sdk";

export default function Logout(): React.ReactElement {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (google?.accounts !== undefined) {
        google.accounts.id.disableAutoSelect();
      } else {
        // throw "new exception";
      }
    } catch (err) {
      // Nothing, Just passingby ( Hello there !);
      if (faro?.api?.pushError) {
        faro.api.pushError(err);
      }
    }
    clearStoredSession();
    navigate("/login");
  });
  return <div>logging out....</div>;
}
