import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { ReactComponent as AppLogo } from "./../../assets/icons/light/skillupmain.svg";
import { ReactComponent as AppLogoDark } from "../../assets/icons/dark/skillupmain.svg";
// import { ReactComponent as NotificationIcon } from "./../../assets/icons/light/notificationsicon.svg";
// import { ReactComponent as NotificationIconDark } from "./../../assets/icons/dark/notificationsicon.svg";
import { ReactComponent as SearchIcon } from "./../../assets/icons/light/searchicon.svg";
import { ReactComponent as SearchIconDark } from "./../../assets/icons/dark/searchicon.svg";
import { ReactComponent as ThemeIcon } from "./../../assets/icons/light/themeicon.svg";
import { ReactComponent as ThemeIconDark } from "./../../assets/icons/dark/themeicon.svg";
import { ReactComponent as UploadIcon } from "./../../assets/icons/light/uploadicon.svg";
import { ReactComponent as UploadIconDark } from "./../../assets/icons/dark/uploadicon.svg";
import { ReactComponent as WishlistNavIcon } from "./../../assets/icons/light/wishlisticon.svg";
import { ReactComponent as WishlistNavIconDark } from "./../../assets/icons/dark/wishlisticon.svg";
import { ReactComponent as ProfilePlaceholder } from "./../../assets/icons/light/profile-placeholder.svg";
import { ReactComponent as ProfilePlaceholderDark } from "./../../assets/icons/dark/profile-placeholder.svg";
import { ReactComponent as BlackSearchIcon } from "../../assets/icons/light/BlackSearchIcon.svg";
import { ReactComponent as BlackSearchIconDark } from "../../assets/icons/dark/BlackSearchIcon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/light/crossicon.svg";
import { ReactComponent as CrossIconDark } from "../../assets/icons/dark/crossicon.svg";
import { ReactComponent as PurpleLeftArrow } from "../../assets/icons/light/PurpleLeftArrow.svg";
import { ReactComponent as PurpleLeftArrowDark } from "../../assets/icons/dark/PurpleLeftArrow.svg";
import Placeholder from "../../assets/icons/light/placeholder_bg.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/light/down-arrow.svg";
import { ReactComponent as DownArrowDark } from "../../assets/icons/dark/down-arrow.svg";
import { ReactComponent as UpArrow } from "../../assets/icons/light/dropupicon.svg";
import { ReactComponent as UpArrowDark } from "../../assets/icons/dark/dropupicon.svg";

import ProfileDropDown from "../../components/ProfileDropDown";
import { ThemeContext } from "../../context/ThemeContext";
import { getCurrentUserRole } from "../../lib/auth";
import UserIcon from "../../assets/icons/light/user_icon.svg";
import { getCurrentOrganizationOfUser } from "../../lib/models/Organization";
import OrgDashboardDropDown from "../../components/OrganizationDropdown";
import { faro } from "@grafana/faro-web-sdk";

export default function Header(): React.ReactElement {
  const profileURL = JSON.parse(
    localStorage.getItem("userData") ?? "{}"
  )?.profileImage;
  const name = JSON.parse(localStorage.getItem("userData") ?? "{}")?.name;
  const email = JSON.parse(localStorage.getItem("userData") ?? "{}")?.email;
  const [orgLogo, setOrgLogo] = useState("");
  const navigate = useNavigate();
  const userRole = getCurrentUserRole();
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [mobileSearchBarState, setMobileSearchBarState] = useState(false);
  const dashboardSearch = (): void => {
    const inputTextElement = document.querySelector(
      "#dashboard_input_text"
    ) as HTMLInputElement;
    const inputText = encodeURIComponent(inputTextElement.value);
    if (inputText !== "") {
      navigate({ pathname: "/search", search: `?q=${inputText}` });
    }
  };
  const ROLES = {
    STUDENT: "STUDENT",
    ADMIN: "ADMIN",
    TRAINER: "TRAINER",
  };
  const [profileDropDown, setProfileDropDown] = useState(false);
  const { theme, updateTheme } = useContext(ThemeContext);
  const [render, setRender] = useState(false);
  useEffect(() => {
    getCurrentOrganizationOfUser()
      .then((org) => {
        setOrgLogo(org.logoUrl);
      })
      .catch((err) => {
        if (faro?.api?.pushError) {
          faro.api.pushError(err);
        }
      });
  }, [render]);

  return (
    <header className="Header">
      <Link className="org-logo-link" to="/dashboard">
        {theme === "light" ? <AppLogo /> : <AppLogoDark />}
      </Link>
      <div className="header-links">
        <div className="icon disabled-button" id="upload">
          {theme === "light" ? <UploadIcon /> : <UploadIconDark />}
        </div>
        {userRole === ROLES.STUDENT && (
          <Link className="icon" id="wishlist" to="/courses/favourite">
            {theme === "light" ? <WishlistNavIcon /> : <WishlistNavIconDark />}
          </Link>
        )}
        {mobileSearchBarState ? (
          <div className="mobile-search-bar-wrapper">
            <div className="left-arrow-wrapper">
              <div
                className="left-arrow"
                onClick={() => {
                  setMobileSearchBarState(false);
                }}
              >
                {theme === "light" ? (
                  <PurpleLeftArrow />
                ) : (
                  <PurpleLeftArrowDark />
                )}
              </div>
            </div>
            <div className="mobile-search-bar">
              <div className="search-icon">
                <div
                  className="my-search-icon"
                  onClick={() => {
                    dashboardSearch();
                  }}
                >
                  {theme === "light" ? (
                    <BlackSearchIcon />
                  ) : (
                    <BlackSearchIconDark />
                  )}
                </div>
              </div>
              <input
                type="text"
                placeholder="Search"
                className="search-input"
                id="dashboard_input_text"
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.key === "Enter") {
                    dashboardSearch();
                  }
                }}
              ></input>
              <div className="cross-icon">
                <div
                  className="cross-icon-svg"
                  onClick={() => {
                    (
                      document.querySelector(
                        "#dashboard_input_text"
                      ) as HTMLInputElement
                    ).value = "";
                  }}
                >
                  {theme === "light" ? <CrossIcon /> : <CrossIconDark />}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="searchbar">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            id="dashboard_input_text"
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.key === "Enter") {
                dashboardSearch();
              }
            }}
          ></input>
          <div
            className="search-icon"
            onClick={() => {
              dashboardSearch();
            }}
          >
            {theme === "light" ? <SearchIcon /> : <SearchIconDark />}
          </div>
        </div>
        <div
          className="icon"
          id="theme"
          onClick={() => {
            updateTheme();
          }}
        >
          {theme === "light" ? <ThemeIcon /> : <ThemeIconDark />}
        </div>
        {/* <div className="icon disabled-button" id="notification">
          {theme === "light" ? <NotificationIcon /> : <NotificationIconDark />}
        </div> */}
        <div
          className="icon"
          id="mobile-search-icon"
          onClick={() => {
            setMobileSearchBarState(true);
          }}
        >
          {theme === "light" ? <BlackSearchIcon /> : <BlackSearchIconDark />}
        </div>

        <div
          className="org-wrapper"
          id="org-wrapper"
          onClick={() => {
            setShowOrgDropdown(true);
          }}
        >
          <div className="logo-wrapper">
            <img
              className="org-image"
              src={orgLogo}
              alt=""
              referrerPolicy="no-referrer"
              onError={(e) => {
                (e.target as HTMLInputElement).onerror = null;
                (e.target as HTMLInputElement).src = Placeholder;
              }}
            ></img>
            <div className="expand-wrapper">
              {theme === "light" ? (
                showOrgDropdown ? (
                  <UpArrow />
                ) : (
                  <DownArrow />
                )
              ) : showOrgDropdown ? (
                <UpArrowDark />
              ) : (
                <DownArrowDark />
              )}
            </div>
          </div>
          {showOrgDropdown && (
            <OrgDashboardDropDown
              renderAgain={() => {
                setRender((render) => !render);
              }}
              setOrgDashboardDropDown={setShowOrgDropdown}
            />
          )}
        </div>
        <div
          className="user-wrapper"
          id="user"
          onClick={() => {
            setProfileDropDown(true);
          }}
        >
          {profileURL === "" ? (
            <>
              {theme === "light" ? (
                <ProfilePlaceholder />
              ) : (
                <ProfilePlaceholderDark />
              )}
            </>
          ) : (
            <img
              className="profile-image"
              src={profileURL}
              alt=""
              referrerPolicy="no-referrer"
              onError={(e) => {
                (e.target as HTMLInputElement).onerror = null;
                (e.target as HTMLInputElement).src = UserIcon;
              }}
            ></img>
          )}
          {profileDropDown ? (
            <ProfileDropDown
              profileURL={profileURL}
              name={name}
              email={email}
              ROLES={ROLES}
              userRole={userRole ?? ""}
              setProfileDropDown={setProfileDropDown}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </header>
  );
}
