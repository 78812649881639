// import { getCurrentOrganizationOfUser } from "./models/Organization";
import { faro } from "@grafana/faro-web-sdk";
import { type ICollaborator } from "./models/Trainer";

export interface ISessionData {
  userData: {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    profileImage: string;
    authToken: string;
    idToken: string;
    refreshToken: string;
  };
}

export interface ILoginResponse {
  accessToken: string;
  refreshToken: string;
  id: number;
}

function getStoredSessionData(): ISessionData {
  return {
    userData: JSON.parse(localStorage.getItem("userData") ?? "{}"),
  };
}

function setStoredSessionData(sessionData: ISessionData): void {
  localStorage.setItem("userData", JSON.stringify(sessionData.userData));
}

function getRefreshTokenFromSession(): string {
  return getStoredSessionData()?.userData.refreshToken ?? "";
}

function setSessionStoredData(data: { key: string; value: string }): void {
  localStorage.setItem(data.key, data.value);
}

function clearStoredSession(): void {
  localStorage.removeItem("userData");
  localStorage.removeItem("theme");
}

function isUserLoggedIn(): boolean {
  return getStoredSessionData()?.userData?.idToken !== undefined;
}

function getSessionStoredData(key: string): string {
  return localStorage.getItem(key) ?? "";
}

function getTheme(): string {
  const temp = getSessionStoredData("theme") ?? "light";
  setSessionStoredData({ key: "theme", value: temp });
  return `${["light", "dark"].includes(temp) ? temp : "light"}`;
}

function getPreferredColorScheme(): string {
  if (window.matchMedia !== undefined && window.matchMedia != null) {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return "dark";
    } else {
      return "light";
    }
  }
  return "light";
}

function getAuthTokenFromLocalStorage(): string {
  return getStoredSessionData().userData.authToken ?? "";
}

function parseJwtToken(token: string): any {
  const base64Url = token.split(".")[1];
  if (base64Url === undefined) {
    localStorage.clear();
    window.location.href = "/logout";
    return {};
  }
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  let response = {};
  try {
    response = JSON.parse(jsonPayload);
  } catch (error: any) {
    response = {};
    if (faro?.api?.pushError) {
      faro.api.pushError(error);
    }
  }
  return response;
}

function getCurrentUserRole(): string {
  const token = getAuthTokenFromLocalStorage();
  if (token !== "") {
    if (parseJwtToken(token).organizationId === undefined) {
      // if there is no field of organization_id it means user have not selected anything
      return "";
    } else {
      // else find the current role
      if (parseJwtToken(token).currentRole === undefined) {
        localStorage.clear();
        window.location.reload();
      }
      return parseJwtToken(token).currentRole?.toUpperCase() ?? "";
    }
  } else return "";
}

function getAllUserRoles(): string[] {
  const token = getAuthTokenFromLocalStorage();
  if (token !== "")
    return parseJwtToken(token).roles.map((role: string) => role.toUpperCase());
  else return [""];
}

function modifyAuthToken(jwt: string): void {
  const sessionUserData = getStoredSessionData().userData;
  sessionUserData.authToken = jwt;
  setStoredSessionData({ userData: sessionUserData });
}

function getUserIDFromJWT(): number {
  return parseJwtToken(getAuthTokenFromLocalStorage()).userId ?? -1;
}

export const checkIsUserInCollaborators = (
  collaborators: ICollaborator[]
): boolean => {
  const userId = getUserIDFromJWT();
  for (const collaborator of collaborators) {
    if (collaborator.id === userId) {
      return true;
    }
  }
  return false;
};

// export const updateCurrentOrganizationOfUser = () => {
//   getCurrentOrganizationOfUser().then((org) => {
//     localStorage.setItem("org", JSON.stringify(org));
//   });
// };

export const checkIfUserisSuperAdmin = (): boolean => {
  const token = getAuthTokenFromLocalStorage();
  if (token === "") {
    return false;
  }
  if (parseJwtToken(token).isSuperAdmin === true) {
    return true;
  }
  return false;
};

export const getCurrentOrganizationFromJWT = (): number => {
  const token = getAuthTokenFromLocalStorage();
  if (token === "") {
    return -1;
  }
  return +parseJwtToken(token).organizationId;
};
export {
  parseJwtToken,
  clearStoredSession,
  getStoredSessionData,
  setStoredSessionData,
  isUserLoggedIn,
  getTheme,
  getPreferredColorScheme,
  getAllUserRoles,
  getAuthTokenFromLocalStorage,
  getCurrentUserRole,
  modifyAuthToken,
  getUserIDFromJWT,
  setSessionStoredData,
  getSessionStoredData,
  getRefreshTokenFromSession,
};
