import React from "react";
import { Link } from "react-router-dom";
import { type IAllBatchesAdmin } from "../../lib/models/Batch";
import { convertToDateFormat } from "../TimelineCourseCard";
import { ReactComponent as StudentIcon } from "../../assets/icons/light/student.svg";
import "./style.scss";

interface IBatchCardProp {
  batch: IAllBatchesAdmin;
  handleClick: (name: string) => void;
}

function BatchCard({ batch, handleClick }: IBatchCardProp): React.ReactElement {
  const { id, name, streamName, endDate, createdAt, noOfStudents } = batch;
  return (
    <Link
      className="BatchCard"
      to={`/batch/${id}`}
      onClick={() => {
        handleClick(name);
      }}
    >
      <div className="batch-details-wrapper">
        <div className="name">{name}</div>
        <div className="stream-name">Stream: {streamName}</div>
        <div className="no-of-students">
          <div className="icon-wrapper">
            <StudentIcon />
          </div>
          {noOfStudents} Students
        </div>
      </div>
      <div className="date-wrapper">
        <div className="created-at">
          Start Date: {convertToDateFormat(createdAt)}
        </div>
        {endDate && (
          <div className="created-at">
            End Date: {convertToDateFormat(endDate)}
          </div>
        )}
      </div>
    </Link>
  );
}

export default BatchCard;
