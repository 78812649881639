import React from "react";
import { type IAppProps } from "./App";
import { checkIfUserisSuperAdmin } from "./lib/auth";
import PageNotFound from "./pages/404";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ValidateAndJoinOrg from "./pages/ValidateAndJoinOrg";
const AdditionalResourcesContainer = React.lazy(
  async () => await import("./containers/AdditionalResourcesContanier")
);
const BasicCourseDetailsForm = React.lazy(
  async () => await import("./containers/BasicCourseDetailsForm")
);
const ChapterFormsContainer = React.lazy(
  async () => await import("./containers/ChapterFormsContainer")
);
const UpdateOrganization = React.lazy(
  async () => await import("./pages/Admin/UpdateOrganization")
);
const CreateOrganization = React.lazy(
  async () => await import("./pages/Admin/CreateOrganization")
);
const AllOrganizations = React.lazy(
  async () => await import("./pages/Admin/AllOrganizations")
);
const OrganizationPage = React.lazy(
  async () => await import("./pages/Admin/Organization")
);
const AdminAllStreams = React.lazy(
  async () => await import("./pages/Admin/AllStreams")
);
const AdminAllBatches = React.lazy(
  async () => await import("./pages/Admin/AllBatches")
);
const AdminAllCourses = React.lazy(
  async () => await import("./pages/Admin/AllCourses")
);
const AdminAllPaths = React.lazy(
  async () => await import("./pages/Admin/AllPaths")
);
const BatchCreation = React.lazy(
  async () => await import("./pages/Admin/BatchCreation")
);
const BatchPage = React.lazy(
  async () => await import("./pages/Admin/BatchPage")
);
const BatchUpdation = React.lazy(
  async () => await import("./pages/Admin/BatchUpdation")
);
// import CertificateApprovals from "./pages/Admin/CertificateApprovals"
const CourseCreation = React.lazy(
  async () => await import("./pages/Admin/CourseCreation")
);
const CourseDetails = React.lazy(
  async () => await import("./pages/Admin/CourseDetails")
);
const CourseUpdation = React.lazy(
  async () => await import("./pages/Admin/CourseUpdation")
);
const AdminDashboard = React.lazy(
  async () => await import("./pages/Admin/Dashboard")
);
const PathCreation = React.lazy(
  async () => await import("./pages/Admin/PathCreation")
);
const PathDetails = React.lazy(
  async () => await import("./pages/Admin/PathDetails")
);
const PathUpdation = React.lazy(
  async () => await import("./pages/Admin/PathUpdation")
);
const ReportPage = React.lazy(
  async () => await import("./pages/Admin/ReportPage")
);
const AdminSearchResultPage = React.lazy(
  async () => await import("./pages/Admin/SearchResultPage")
);
const StreamCreation = React.lazy(
  async () => await import("./pages/Admin/StreamCreation")
);
const StreamPage = React.lazy(
  async () => await import("./pages/Admin/StreamPage")
);
const StreamUpdation = React.lazy(
  async () => await import("./pages/Admin/StreamUpdation")
);
const StudentProfilePage = React.lazy(
  async () => await import("./pages/Admin/StudentProfile")
);
const TrainerProfilePage = React.lazy(
  async () => await import("./pages/Admin/TrainerProfile")
);
const AllStudentsPage = React.lazy(
  async () => await import("./pages/Admin/Students")
);
const AllTrainersPage = React.lazy(
  async () => await import("./pages/Admin/Trainers")
);
const StudentAllBatches = React.lazy(
  async () => await import("./pages/Student/AllBatches")
);
const StudentAllCourses = React.lazy(
  async () => await import("./pages/Student/AllCourses")
);
const StudentAllPaths = React.lazy(
  async () => await import("./pages/Student/AllPaths")
);
const StudentBatch = React.lazy(
  async () => await import("./pages/Student/BatchPage")
);
const Course = React.lazy(async () => await import("./pages/Student/Course"));
const StudentDashboard = React.lazy(
  async () => await import("./pages/Student/Dashboard")
);
const FavouriteCourse = React.lazy(
  async () => await import("./pages/Student/FavouriteCourse")
);
const OngoingCourse = React.lazy(
  async () => await import("./pages/Student/OngoingCourse")
);
const OngoingPath = React.lazy(
  async () => await import("./pages/Student/OngoingPath")
);
const Invitations = React.lazy(
  async () => await import("./pages/Student/Invitations")
);
const StudentPathPage = React.lazy(
  async () => await import("./pages/Student/PathPage")
);
// import StudentProfile from "./pages/Student/Profile"
const SearchResultPage = React.lazy(
  async () => await import("./pages/Student/SearchResultPage")
);

interface Iroutes {
  path: string;
  component:
    | typeof React.Component
    | React.FC<IAppProps>
    | React.LazyExoticComponent<React.FC<IAppProps>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.LazyExoticComponent<any>;
  nestedRoutes?: Array<{
    path: string;
    component:
      | typeof React.Component
      | React.FC<IAppProps>
      | React.LazyExoticComponent<React.FC<IAppProps>>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | React.LazyExoticComponent<any>;
  }>;
}

const commonRoutes: Iroutes[] = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "invitations",
    component: Invitations,
  },
];

const superAdminRoutes: Iroutes[] = [
  {
    path: "/organization/new",
    component: CreateOrganization,
  },
  {
    path: "/organization/:id/update",
    component: UpdateOrganization,
  },
  {
    path: "/organizations/all",
    component: AllOrganizations,
  },
];

const organizationRoutes: Iroutes[] = [
  {
    path: "/orgs/join",
    component: ValidateAndJoinOrg,
  },
  {
    path: "*",
    component: ValidateAndJoinOrg,
  },
];

const studentRoutes: Iroutes[] = [
  {
    path: "/dashboard",
    component: StudentDashboard,
  },
  {
    path: "/paths/all",
    component: StudentAllPaths,
  },
  {
    path: "/courses/all",
    component: StudentAllCourses,
  },
  {
    path: "/courses/favourite",
    component: FavouriteCourse,
  },
  {
    path: "/course/:id",
    component: Course,
  },
  {
    path: "/path/:id",
    component: StudentPathPage,
  },
  {
    path: "/paths/ongoing",
    component: OngoingPath,
  },
  {
    path: "/courses/ongoing",
    component: OngoingCourse,
  },
  {
    path: "/search",
    component: SearchResultPage,
  },
  {
    path: "/batch/:id",
    component: StudentBatch,
  },
  {
    path: "/batches/all",
    component: StudentAllBatches,
  },
  {
    path: "/user/:id",
    component: StudentProfilePage,
  },
  {
    path: "/quiz/report",
    component: ReportPage,
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const adminRoutes: Iroutes[] = [
  {
    path: "/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/organization/:id",
    component: OrganizationPage,
  },
  // {
  //     path: "/approvals",
  //     component: CertificateApprovals
  // },
  {
    path: "/user/students/all",
    component: AllStudentsPage,
  },
  {
    path: "/user/student/:id",
    component: StudentProfilePage,
  },
  {
    path: "/user/trainers/all",
    component: AllTrainersPage,
  },
  {
    path: "/user/trainer/:id",
    component: TrainerProfilePage,
  },
  // {
  //     path: "/approvals",
  //     component: CertificateApprovals
  // },
  {
    path: "/paths/all",
    component: AdminAllPaths,
  },
  {
    path: "/courses/all",
    component: AdminAllCourses,
  },
  {
    path: "/course/new",
    component: CourseCreation,
    nestedRoutes: [
      {
        path: "/course/new",
        component: BasicCourseDetailsForm,
      },
    ],
  },
  {
    path: "/path/new",
    component: PathCreation,
  },
  {
    path: "/batch/new",
    component: BatchCreation,
  },
  {
    path: "/path/update/:id",
    component: PathUpdation,
  },
  {
    path: "/search",
    component: AdminSearchResultPage,
  },
  {
    path: "/course/:id/update",
    component: CourseUpdation,
    nestedRoutes: [
      {
        path: "/course/:id/update/1",
        component: BasicCourseDetailsForm,
      },
      {
        path: "/course/:id/update/2",
        component: ChapterFormsContainer,
      },
      {
        path: "/course/:id/update/3",
        component: AdditionalResourcesContainer,
      },
    ],
  },
  {
    path: "/path/:id",
    component: PathDetails,
  },
  {
    path: "/course/:id",
    component: CourseDetails,
  },
  {
    path: "/stream/new",
    component: StreamCreation,
  },
  {
    path: "/stream/update/:id",
    component: StreamUpdation,
  },
  {
    path: "/stream/:id",
    component: StreamPage,
  },
  {
    path: "/batch/:id",
    component: BatchPage,
  },
  {
    path: "/batch/update/:id",
    component: BatchUpdation,
  },
  {
    path: "/streams/all",
    component: AdminAllStreams,
  },
  {
    path: "/batches/all",
    component: AdminAllBatches,
  },
  {
    path: "/quiz/report",
    component: ReportPage,
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const trainerRoutes: Iroutes[] = [
  {
    path: "/dashboard",
    component: AdminDashboard,
  },
  // {
  //     path: "/approvals",
  //     component: CertificateApprovals
  // },
  {
    path: "/user/:id",
    component: TrainerProfilePage,
  },
  {
    path: "/user/students/all",
    component: AllStudentsPage,
  },
  {
    path: "/user/student/:id",
    component: StudentProfilePage,
  },
  {
    path: "/paths/all",
    component: AdminAllPaths,
  },
  {
    path: "/courses/all",
    component: AdminAllCourses,
  },
  {
    path: "/course/new",
    component: CourseCreation,
    nestedRoutes: [
      {
        path: "/course/new",
        component: BasicCourseDetailsForm,
      },
    ],
  },
  {
    path: "/path/new",
    component: PathCreation,
  },
  {
    path: "/path/update/:id",
    component: PathUpdation,
  },
  {
    path: "/search",
    component: AdminSearchResultPage,
  },
  {
    path: "/course/:id/update",
    component: CourseUpdation,
    nestedRoutes: [
      {
        path: "/course/:id/update/1",
        component: BasicCourseDetailsForm,
      },
      {
        path: "/course/:id/update/2",
        component: ChapterFormsContainer,
      },
      {
        path: "/course/:id/update/3",
        component: AdditionalResourcesContainer,
      },
    ],
  },
  {
    path: "/path/:id",
    component: PathDetails,
  },
  {
    path: "/course/:id",
    component: CourseDetails,
  },
  {
    path: "/stream/:id",
    component: StreamPage,
  },
  {
    path: "/batch/:id",
    component: BatchPage,
  },
  {
    path: "/batch/update/:id",
    component: BatchUpdation,
  },
  {
    path: "/streams/all",
    component: AdminAllStreams,
  },
  {
    path: "/batches/all",
    component: AdminAllBatches,
  },
  {
    path: "/quiz/report",
    component: ReportPage,
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

let routes: Iroutes[] = [];

const getRoutes = (appMode: string | null): Iroutes[] => {
  routes = [...commonRoutes, ...organizationRoutes];
  switch (appMode) {
    case "STUDENT":
      routes = [...commonRoutes, ...studentRoutes];
      if (checkIfUserisSuperAdmin()) routes = [...routes, ...superAdminRoutes];
      break;
    case "ADMIN":
      routes = [...commonRoutes, ...adminRoutes];
      if (checkIfUserisSuperAdmin()) routes = [...routes, ...superAdminRoutes];
      break;
    case "TRAINER":
      routes = [...commonRoutes, ...trainerRoutes];
      if (checkIfUserisSuperAdmin()) routes = [...routes, ...superAdminRoutes];
      break;
    default:
      break;
  }
  return routes;
};

export { getRoutes };
