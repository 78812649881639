import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { type IPathinBatch } from "../../lib/models/BatchDescription";
import { type ICourseAdmin } from "../../lib/models/Course";
import { convertToDateFormat } from "../TimelineCourseCard";
import PlaceHolder from "../../assets/icons/light/placeholder.svg";
import "./style.scss";
import { RoleContext } from "../../App";

interface CourseCardInPathPageProps {
  course?: ICourseAdmin;
  path?: IPathinBatch;
}

export default function CourseCardInPathPage(
  props: CourseCardInPathPageProps
): React.ReactElement {
  const { course, path } = props;
  const { appMode } = useContext(RoleContext);
  return (
    <div className="courseInPathCardWrapper">
      <div className="courseInPathcard">
        <Link
          className="course-in-path-card-left"
          to={
            path !== undefined
              ? `/path/${path.id}`
              : `/course/${course?.courseId ?? ""}`
          }
        >
          <img
            src={course?.imageUrl ?? path?.imageUrl}
            alt=""
            referrerPolicy="no-referrer"
            onError={(e) => {
              (e.target as HTMLInputElement).onerror = null;
              (e.target as HTMLInputElement).src = PlaceHolder;
            }}
          ></img>
        </Link>
        <div className="course-in-path-card-middle">
          <Link
            className="course-name-link"
            to={
              path !== undefined
                ? `/path/${path.id}`
                : `/course/${course?.courseId ?? ""}`
            }
          >
            {course?.courseName ?? path?.name}
          </Link>
          <div>
            By {course?.createdBy.name ?? path?.createdBy.name} |{" "}
            {convertToDateFormat(course?.createdAt ?? path?.createdAt ?? "")}
          </div>
          <div className="course-card-description">
            {course?.about ?? path?.description}
          </div>
        </div>
      </div>
      <div className="mobile-course-in-path-card-description">
        {course?.about ?? path?.description}
      </div>

      {(course ?? path)?.isAuthorised === false ||
      (appMode === "STUDENT" && (course ?? path)?.isAccessible === false) ? (
        <div className="restriction-wrapper not-accessible">
          <div className="bottom-message">Not Accessible</div>
        </div>
      ) : (
        (course ?? path)?.isAccessible === false && (
          <Link
            className="course-name-link"
            to={
              path !== undefined
                ? `/path/${path.id}`
                : `/course/${course?.courseId ?? ""}`
            }
          >
            <div className="restriction-wrapper not-published">
              <div className="bottom-message">Not Published</div>
            </div>
          </Link>
        )
      )}
    </div>
  );
}
