import React, {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { FaroErrorBoundary, withFaroProfiler } from "@grafana/faro-react";
import LoadingAnimation from "./components/LoadingAnimation";
import { getCurrentUserRole } from "./lib/auth";
import useGaTracker from "./lib/hooks/useGoogleAnalytics";
import { getRoutes } from "./routes";
import { ThemeContext, ThemeProvider } from "./context/ThemeContext";
import { initializeFaro } from "./initialize";

export interface IAppHooks {
  requestSessionValidation: () => void;
}

export interface IAppProps {
  appHooks: IAppHooks;
}

export interface IRoleContext {
  appMode: string;
  setAppMode: (appMode: string) => void;
}

export const RoleContext = createContext<IRoleContext>({
  appMode: "",
  setAppMode: () => {
    //
  },
});

const App: React.FunctionComponent<Record<string, unknown>> = () => {
  useEffect(() => {
    initializeFaro();
  }, []);
  return (
    <ThemeProvider>
      <FaroErrorBoundary>
        <AppChild />
      </FaroErrorBoundary>
    </ThemeProvider>
  );
};

const AppChild = (): React.ReactElement => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`App ${theme}`}>
      <Suspense
        fallback={
          <div
            style={{
              backgroundColor: `${theme === "light" ? "#f3f5f9" : "#010101"}`,
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingAnimation />
          </div>
        }
      >
        <Router>
          <RenderApp />
        </Router>
      </Suspense>
    </div>
  );
};

const RenderApp: React.FunctionComponent = () => {
  useGaTracker();
  const [appMode, setAppMode] = useState(getCurrentUserRole() ?? "");
  const routes = getRoutes(appMode);

  function requestSessionValidation(): void {
    setAppMode(getCurrentUserRole());
  }
  return (
    <>
      <RoleContext.Provider value={{ appMode, setAppMode }}>
        <Routes>
          {routes.map(({ path, component: Component, nestedRoutes }) => (
            <Route
              key={path}
              path={path}
              element={
                <Component appHooks={{ requestSessionValidation }}></Component>
              }
            >
              {nestedRoutes?.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Component
                      appHooks={{ requestSessionValidation }}
                    ></Component>
                  }
                />
              ))}
            </Route>
          ))}
        </Routes>
      </RoleContext.Provider>
    </>
  );
};

export default withFaroProfiler(App);
