import React, { Component, useEffect } from "react";
import Helmet from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { isUserLoggedIn } from "../lib/auth";
import StandardLayout from "../containers/Layouts/Standard";
import { ReactComponent as NotExistGraphic } from "../assets/icons/light/oops-illustration.svg";
import PageHeader from "../components/PageHeader";

@StandardLayout
export default class PageNotFound extends Component {
  render(): React.ReactElement {
    return <PageNotFoundPage />;
  }
}

export function PageNotFoundPage(): React.ReactElement {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserLoggedIn()) {
      const intendedURL = window.location.pathname;
      localStorage.setItem("intendedURL", intendedURL);
      navigate("/login");
    }
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <Helmet>
        <title>404 Page Not Found | Training and Upskilling</title>
        <meta
          name="description"
          content="You have visited the wrong page, please check your URL"
        />
      </Helmet>
      <NotExistGraphic />
      <PageHeader title="404 | Page Not Found" />
      <div className="error-message">
        <h3>Are you lost?</h3>
        <Link to="/login">
          <h4>Go to Home Page</h4>
        </Link>
      </div>
    </div>
  );
}
