import Api from "../api";
import { type ILoginResponse } from "../auth";

// there is no specific interface for this
const getAuthToken = async (): Promise<ILoginResponse> => {
  const resObj = await Api.post({ url: "login" });
  return resObj;
};

const switchUserRole = async (newRole: string): Promise<string> => {
  return await Api.patch({
    url: "/user/switch-role",
    data: { newRole },
  });
};

export { getAuthToken, switchUserRole };
