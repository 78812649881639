import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { MEASUREMENT_ID } from "../../constants";

const useGaTracker = (): void => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const MEASUREMENTID: any = MEASUREMENT_ID;
  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(MEASUREMENTID);
    }
    setInitialized(true);
  }, [MEASUREMENTID]);

  useEffect(() => {
    if (initialized) {
      setTimeout(() => {
        ReactGA.send("pageview");
      }, 2500);
    }
  }, [initialized, location]);
};

export const gaEvent = (
  action: string,
  label: string,
  category: string
): void => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export default useGaTracker;
