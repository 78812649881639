import React, { useEffect } from "react";
import { ReactComponent as LandingPage2One } from "../../assets/icons/light/LandingPage2One.svg";
import { ReactComponent as LandingPage2Two } from "../../assets/icons/light/LandingPage2Two.svg";
import { ReactComponent as LandingPage2Three } from "../../assets/icons/light/LandingPage2Three.svg";
import { ReactComponent as LandingPage2Four } from "../../assets/icons/light/LandingPage2Four.svg";
import { ReactComponent as Page4Trainer } from "../../assets/icons/light/Page4Trainer.svg";
import { ReactComponent as Page6Admin } from "../../assets/icons/light/Page6Admin.svg";
import { ReactComponent as Page6ItemCourse } from "../../assets/icons/light/Page6ItemCourse.svg";
import { ReactComponent as Page6ItemPath } from "../../assets/icons/light/Page6ItemPath.svg";
import { ReactComponent as Page6ItemStream } from "../../assets/icons/light/Page6ItemStream.svg";
import { ReactComponent as Page6ItemBatch } from "../../assets/icons/light/Page6ItemBatch.svg";
import { ReactComponent as Page7Certificate } from "../../assets/icons/light/page7Certificate.svg";
import { ReactComponent as AppLogo } from "../../assets/icons/light/skillupmain.svg";
import { ReactComponent as AppLogoDark } from "../../assets/icons/dark/skillupmain.svg";
import { ReactComponent as Page1MainIcon } from "../../assets/icons/light/Page1MainIcon.svg";
// import { ReactComponent as Page1Cap } from "../../assets/icons/light/page1Cap.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/light/RightArrow.svg";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import BatchCard from "../../components/BatchCard";
import PathCard from "../../components/PathCard";
import StreamCard from "../../components/StreamCard";
import { batchCardsBackgroundImages } from "../../constants";
import CourseCard from "../../components/CourseCard";
import { isUserLoggedIn } from "../../lib/auth";
import { gaEvent } from "../../lib/hooks/useGoogleAnalytics";

function HomePage(): React.ReactElement {
  const navigate = useNavigate();
  useEffect(() => {
    if (isUserLoggedIn()) {
      const myURL = localStorage.getItem("intendedURL");
      localStorage.removeItem("intendedURL");
      if (myURL !== null) {
        navigate(myURL);
      } else navigate("/login");
    }
  });
  const handleStreamClick = (name: string): void => {
    gaEvent("user_clicked", `stream_clicked: ${name}`, "Stream");
  };
  const handleCourseClick = (name: string): void => {
    gaEvent("user_clicked", `course_clicked: ${name}`, "Course");
  };
  const handleBatchClick = (name: string): void => {
    gaEvent("user_clicked", `batch_clicked: ${name}`, "Batch");
  };
  const Page1 = (): React.ReactElement => {
    return (
      <div className="page1 page">
        <div className="page1Background">
          <div className="cap-svg-wrapper">{/* <Page1Cap /> */}</div>
        </div>
        <div className="page1Foreground">
          <div className="page1Top">
            <div className="page1LogoWrapper">
              <AppLogoDark />
            </div>
            <div className="page1ActionsWrapper">
              {/* <span className="contact-us">Contact us</span> */}
              <button
                className="get-started"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
                <span className="mobile-right-wrapper">
                  <RightArrow />
                </span>
              </button>
            </div>
          </div>
          <div className="page1ContentWrapper">
            <div className="page1TextHolder">
              <div className="page1Title">
                Take Your Learning To The Next Level
              </div>
              <div className="page1Description">
                Get unlimited access to structured courses & trained sessions
              </div>
              <div
                className="get-started-button-wrapper"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <span className="get-started-main-text">Get Started</span>
                <RightArrow />
              </div>
            </div>
            <div className="page1SvgHolder">
              <Page1MainIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Page2 = (): React.ReactElement => {
    const page2Content = [
      {
        svg: <LandingPage2One />,
        title: "Online Courses",
        description:
          "Find the best content curated by domain experts from across the world at your fingertips.  Explore trending topics and Upskill yourself",
      },
      {
        svg: <LandingPage2Two />,
        title: "Top Trainers",
        description:
          "Our platform provides way to cross the educational barriers by providing access to the knowledge from top trainers across the world",
      },
      {
        svg: <LandingPage2Three />,
        title: "Enroll Batches",
        description:
          "Learn, connect and collaborate with people of same interest and make a step forward towards your Goal",
      },
      {
        svg: <LandingPage2Four />,
        title: "Get Certified",
        description:
          "Place yourself on the top of modernised competition with our valuable certificates. Stand out by showcasing your knowledge",
      },
    ];
    return (
      <div className="page2 page">
        {() => {
          window.scrollTo(0, 0);
        }}
        {page2Content.map((page2Item, index) => {
          return (
            <div className="page2Item" key={index}>
              <div className="svg-holder">{page2Item.svg}</div>
              <div className="page2ItemTitle">{page2Item.title}</div>
              <div className="page2ItemDescription">
                {page2Item.description}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const Page3 = (): React.ReactElement => {
    return (
      <div className="page3 page">
        <div className="page3TextHolder">
          <div className="page3Title">Choose what you want to learn?</div>
          <div className="page3TitleUnderline"></div>
          <div className="page3Description">
            Our platform provides multiple paths and courses from popular
            domains like python, java, machine learning etc. You can utilize the
            batches to learn with peers and get personal insights from expert
            trainers.
          </div>
        </div>
        <div className="page3CardsHolder">
          <div className="page3CardsTopWrapper">
            <div className="stream-card-wrapper">
              <span className="card-title">Streams</span>
              <div className="page3Card">
                <StreamCard
                  index={Math.floor(Math.random() * 3)}
                  stream={{
                    streamId: 0,
                    streamName: "IOS Devolopment",
                    createdBy: {
                      name: "Train and Upskill",
                      id: -1,
                      email: "",
                      imageUrl: "",
                    },
                    createdAt: "2022-12-20",
                  }}
                  handleClick={handleStreamClick}
                />
              </div>
            </div>
          </div>
          <div className="page3CardsBottomWrapper">
            <div className="batch-card-wrapper">
              <span className="card-title">Batches</span>
              <div
                className="page3Card"
                style={{
                  backgroundImage:
                    batchCardsBackgroundImages[
                      [1, 3][Math.floor(Math.random() * 2)]
                    ],
                }}
              >
                <BatchCard
                  batch={{
                    id: "0",
                    name: "Java Script : Batch 3",
                    createdBy: {
                      name: "Train and Upskill",
                      id: -1,
                      email: "",
                      imageUrl: "",
                    },
                    createdAt: "2022-12-20",
                    streamName: "Java Script",
                    noOfTrainers: 10,
                    noOfStudents: 10,
                    noOfPaths: 5,
                    endDate: "2022-12-20",
                  }}
                  handleClick={handleBatchClick}
                />
              </div>
            </div>
            <div className="path-card-wrapper">
              <div className="page3Card">
                <PathCard
                  path={{
                    id: 0,
                    name: "Frontend Developer",
                    imageUrl:
                      "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80",
                    noOfCourses: 240,
                    isAccessible: true,
                  }}
                />
              </div>
              <span className="card-title">Paths</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Page4 = (): React.ReactElement => {
    return (
      <div className="page4 page">
        <div className="page4SvgHolder">
          <Page4Trainer />
        </div>
        <div className="page4TextHolder">
          <div className="page4Title">What Trainers can do?</div>
          <div className="page4TitleUnderline"></div>
          <div className="page4Description">
            Trainers can share the knowledge by creating courses , Quizes and
            paths. Trainer can provide professional insights is to the
            enthusiasts in a batch. Trainer can evaluate and analyze the
            progress of students
          </div>
        </div>
      </div>
    );
  };
  const Page5 = (): React.ReactElement => {
    return (
      <div className="page5 page">
        <div className="page5Title">Our Courses</div>
        <div className="page5BottomWrapper">
          <div className="page5CardsBackgroundWrapper"></div>
          <div className="page5CardsHolder">
            <div className="course-card-wrapper">
              <CourseCard
                course={{
                  id: 0,
                  imageUrl:
                    "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80",
                  isFavourite: [true, false][Math.round(Math.random())],
                  name: "User experience & User Interface Design Course",
                  createdBy: {
                    id: 1,
                    name: "Anantha Sai",
                    email: "",
                    imageUrl: "",
                  },
                }}
                handleClick={handleCourseClick}
              />
            </div>
            <div className="course-card-wrapper">
              <CourseCard
                course={{
                  id: 0,
                  imageUrl:
                    "https://images.unsplash.com/photo-1580894742597-87bc8789db3d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
                  isFavourite: [true, false][Math.round(Math.random())],
                  name: "Cyber Security: Beginner's Training Guide to Online Safety!",
                  createdBy: {
                    name: "Ayush Agrawal",
                    id: 1,
                    email: "",
                    imageUrl: "",
                  },
                }}
                handleClick={handleCourseClick}
              />
            </div>
            <div className="course-card-wrapper">
              <CourseCard
                course={{
                  id: 0,
                  imageUrl:
                    "https://images.idgesg.net/images/article/2019/05/java_binary_code_gears_programming_coding_development_by_bluebay2014_gettyimages-1040871468_2400x1600-100795798-large.jpg",
                  isFavourite: [true, false][Math.round(Math.random())],
                  name: "The Complete Java Development Bootcamp",
                  createdBy: {
                    name: "Ujjawal Misra",
                    id: 2,
                    email: "",
                    imageUrl: "",
                  },
                }}
                handleClick={handleCourseClick}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Page6 = (): React.ReactElement => {
    const page6Items = [
      {
        svg: <Page6ItemCourse />,
        backgroundColor: "#f5efdb",
        title: "Create Courses",
      },
      {
        svg: <Page6ItemStream />,
        backgroundColor: "#dceee3",
        title: "Create Streams",
      },
      {
        svg: <Page6ItemBatch />,
        backgroundColor: "#e0e1f5",
        title: "Create Batches",
      },
      {
        svg: <Page6ItemPath />,
        backgroundColor: "#ffe5d7",
        title: "Create Paths",
      },
    ];
    return (
      <div className="page6 page">
        <div className="page6LeftHolder">
          <div className="page6Title">What Admin can do?</div>
          <div className="page6TitleUnderline"></div>
          <div className="page6Description">
            Admin will manage and support the entire Training and upskilling
            ecosysytem. He can introduce trending technology into the platform.
          </div>
          <div className="page6ItemsHolder">
            {page6Items.map((page6Item, index) => {
              return (
                <div className="page6Item" key={index}>
                  <div
                    className="page6ItemSvgHolder"
                    style={{ backgroundColor: `${page6Item.backgroundColor}` }}
                  >
                    {page6Item.svg}
                  </div>
                  <div className="page6ItemTitle">{page6Item.title}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="page6SvgHolder">
          <Page6Admin />
        </div>
      </div>
    );
  };
  const Page7 = (): React.ReactElement => {
    return (
      <div className="page7 page">
        <div className="page7ContentHolder">
          <div className="page7SvgHolder">
            <Page7Certificate />
          </div>
          <div className="page7TextHolder">
            <div className="page7Title">Take Quizzes and Get Certified </div>
            <div className="page7TitleUnderline"></div>
            <div className="page7Description">
              A vaulable certificate will be the judging face of the effort you
              put in gaining any technical knowledge. Training and upskilling
              provides certificate from professional trainers who will support
              and provide feedback. You can design your professional inventory
              equiped with skills and certificates
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer-skillup">
            <AppLogo />
          </div>
          <div className="footer-rights">
            © {new Date().getFullYear()} All Rights Reserved
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="HomePage">
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />
      <Page6 />
      <Page7 />
    </div>
  );
}

export default HomePage;
