import React, { Component } from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import "./style.scss";

export default function Standard(DecoratedComponent: typeof Component): any {
  return class StandardLayoutComponent extends Component {
    render(): React.ReactElement {
      return (
        <>
          <div className="StandardLayout">
            <Header />
            <div className="page-content-wrapper">
              <div className="page-content">
                <DecoratedComponent {...this.props} />
              </div>
            </div>
            <Footer />
          </div>
        </>
      );
    }
  };
}
