import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { ReactComponent as AppLogo } from "./../../assets/icons/light/skillupmain.svg";
import { ReactComponent as AppLogoDark } from "./../../assets/icons/dark/skillupmain.svg";
import { ThemeContext } from "../../context/ThemeContext";
import { getCurrentUserRole } from "../../lib/auth";
// import { ReactComponent as InstagramIcon } from './../../assets/icons/light/instagramicon.svg'
// import { ReactComponent as InstagramIconDark } from './../../assets/icons/dark/instagramicon.svg'
// import { ReactComponent as TwitterIcon } from './../../assets/icons/light/twittericon.svg'
// import { ReactComponent as TwitterIconDark } from './../../assets/icons/dark/twittericon.svg'
// import { ReactComponent as FacebookIcon } from './../../assets/icons/light/facebookicon.svg'
// import { ReactComponent as FacebookIconDark } from './../../assets/icons/dark/facebookicon.svg'
// import { ReactComponent as LinkedinIcon } from './../../assets/icons/light/linkedinicon.svg'
// import { ReactComponent as LinkedinIconDark } from './../../assets/icons/dark/linkedinicon.svg'

function Footer(): React.ReactElement {
  const { theme } = useContext(ThemeContext);
  const userRole = getCurrentUserRole();
  return (
    <div className="Footer">
      <div className="footer-top">
        <div className="icon-wrapper">
          <Link className="app-logo" to="/dashboard">
            {theme === "light" ? <AppLogo /> : <AppLogoDark />}
          </Link>
        </div>
        <div className="links">
          <Link className="footer-link" to="/paths/all">
            All Paths
          </Link>
          <Link className="footer-link" to="/courses/all">
            All Courses
          </Link>
          {userRole === "ADMIN" && (
            <Link className="footer-link" to="/user/students/all">
              All Students
            </Link>
          )}
        </div>
      </div>
      <div className="footer-bottom">
        © {new Date().getFullYear()} All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
