import {
  type IAllBatchesAdmin,
  type IAllBatchesAdminAPIResponse,
  type IBatchAdmin,
  type IBatchAdminApiResponse,
} from "./Batch";
import {
  type IEnrolledBatches,
  type IEnrolledBatchesAPIResponse,
} from "./BatchDescription";
import { type IChapter, type IChapterFromApi } from "./Chapter";
import { type ICourse, type ICourseAdmin } from "./Course";
import {
  type ICourseDetailsAdmin,
  type ICourseDetailsAdminApiResponse,
  type ICourseDetailsStudent,
  type ICourseDetailsStudentApiResponse,
} from "./CourseDescription";
import {
  type ICourseInPathResponse,
  type IEnrolledPathResponse,
} from "./PathDescription";
import { type IQuiz, type IQuizResponse } from "./Quiz";
import {
  type IResultQuiz,
  type IResultQuizApiResponse,
  type IstudentInResult,
  type IstudentInResultApiResponse,
} from "./Result";
import { type IStreamAdmin, type IStreamAdminApiResponse } from "./Stream";
import {
  type IStreamDetailsAdmin,
  type IStreamDetailsAdminApiResponse,
  type IStreamPostRequest,
  type IUpdateStreamRequest,
} from "./StreamDescription";
import { type IStudent, type ITrainersInStreamApiResponse } from "./Student";
import {
  type ICollaborator,
  type ICourseOfTrainer,
  type IPathOfTrainer,
} from "./Trainer";

export const ConvertAdminGetCourseResponse = (
  apiResponseBody: ICourseDetailsAdminApiResponse
): ICourseDetailsAdmin => {
  const courseObj = {} as ICourseDetailsAdmin;
  return {
    ...courseObj,
    courseName: apiResponseBody.courseName,
    courseId: apiResponseBody.courseId,
    about: apiResponseBody.about,
    description: apiResponseBody.description,
    imageUrl: apiResponseBody.imageUrl,
    isAccessible: apiResponseBody.isAccessible,
    prerequisites: apiResponseBody.prerequisites,
    level: apiResponseBody.level,
    createdAt: apiResponseBody.createdAt,
    createdBy: apiResponseBody.createdBy,
    isOwner: apiResponseBody.isOwner,
    updatedAt: apiResponseBody.updatedAt,
    collaborators: apiResponseBody.collaborators,
  };
};

export const ConvertStudentGetCourseResponse = (
  apiResponseBody: ICourseDetailsStudentApiResponse
): ICourseDetailsStudent => {
  return {
    id: apiResponseBody.id,
    name: apiResponseBody.name,
    createdBy: apiResponseBody.createdBy,
    imageUrl: apiResponseBody.imageUrl,
    isFavourite: apiResponseBody.isFavourite,
    isEnrolled: apiResponseBody.isEnrolled,
    about: apiResponseBody.about,
    isAccessible: apiResponseBody.isAccessible,
    description: apiResponseBody.description,
    createdAt: apiResponseBody.createdAt,
    completedAt: apiResponseBody.completedAt,
    noOfChapters: apiResponseBody.noOfChapters,
    noOfEnrollments: apiResponseBody.noOfEnrollments,
    progress: apiResponseBody.progress,
    level: apiResponseBody.level,
    noOfResources: apiResponseBody.noOfResources,
    updatedAt: apiResponseBody.updatedAt,
  };
};

export const ConvertQuizResponse = (apiQuizBody: IQuizResponse[]): IQuiz[] => {
  return apiQuizBody.map((quizItem) => {
    return {
      quizType: quizItem.quizType,
      id: quizItem.id !== undefined ? +quizItem.id : 0,
      name: quizItem.name,
      quizLink: quizItem.link,
      passingMarks: quizItem.passingMarks,
      status: quizItem.status,
    };
  });
};

export const convertAdminGetStreamResponse = (
  streamApiResponse: IStreamDetailsAdminApiResponse
): IStreamDetailsAdmin => {
  return {
    streamId: streamApiResponse.id,
    batches:
      streamApiResponse.batches !== undefined
        ? convertIBatchAdminResponseToIBatchAdmin(streamApiResponse.batches)
        : [],
    streamName: streamApiResponse.name,
    trainers:
      streamApiResponse.trainers !== undefined
        ? convertITrainerResponseToIStudent(streamApiResponse.trainers)
        : [],
    isActive: streamApiResponse.isActive,
    createdAt: streamApiResponse.createdAt,
    createdBy: streamApiResponse.createdBy,
    isOwner: streamApiResponse.isOwner,
  };
};

export const convertAllStreams = (
  apiStreamBody: IStreamAdminApiResponse[]
): IStreamAdmin[] => {
  return apiStreamBody.map((streamItem) => {
    return {
      streamId: streamItem.id,
      streamName: streamItem.name,
      createdBy: streamItem.createdBy,
      createdAt: streamItem.createdAt,
      isActive: streamItem.isActive,
    };
  });
};

export const convertITrainerResponseToIStudent = (
  apiTrainerBody: ITrainersInStreamApiResponse[]
): IStudent[] => {
  return apiTrainerBody.map((trainerBody) => {
    return {
      id: +trainerBody.trainerId,
      name: trainerBody.trainerName,
      email: trainerBody.trainerEmail,
      imageUrl: trainerBody.trainerImageUrl,
    };
  });
};

export const convertIBatchAdminResponseToIBatchAdmin = (
  apiBatchBody: IBatchAdminApiResponse[]
): IBatchAdmin[] => {
  return apiBatchBody.map((batchBody) => {
    return {
      batchId: batchBody.batchId,
      batchName: batchBody.batchName,
      startDate: batchBody.startDate,
      endDate: batchBody.endDate,
      noOfStudents: batchBody.noOfStudents,
      noOfTrainers: batchBody.noOfTrainers,
    };
  });
};

export const convertStreamPosttoStreamUpdate = (
  streamBody: IStreamPostRequest
): IUpdateStreamRequest => {
  return {
    streamName: streamBody.streamName,
    trainers: streamBody.trainers,
    isActive: streamBody.isActive,
    trainersByEmail: streamBody.trainersByEmail,
  };
};

export const convertAllBatches = (
  apiBatchesBody: IAllBatchesAdminAPIResponse[]
): IAllBatchesAdmin[] => {
  return apiBatchesBody.map((batchItem) => {
    return {
      id: batchItem.id,
      name: batchItem.name,
      streamName: batchItem.streamName,
      noOfPaths: batchItem.noOfPaths,
      noOfStudents: batchItem.noOfStudents,
      noOfTrainers: batchItem.noOfTrainers,
      createdAt: batchItem.createdAt,
      createdBy: batchItem.createdBy,
      endDate: batchItem.endDate,
    };
  });
};

export const convertAllEnrolledBatches = (
  enrolledBatches: IEnrolledBatchesAPIResponse[]
): IEnrolledBatches[] => {
  return enrolledBatches.map((batchItem) => {
    return {
      id: batchItem.id,
      name: batchItem.name,
      stream: batchItem.stream,
      progress: batchItem.progress,
      isAuthorised: batchItem.isAuthorised,
    };
  });
};

export const convertIResultQuizApiResponsetoIResultQuiz = (
  quizResultApiBody: IResultQuizApiResponse
): IResultQuiz => {
  return {
    quizId: quizResultApiBody.quizId,
    quizName: quizResultApiBody.quizName,
    createdBy: quizResultApiBody.createdBy,
    totalMarks: quizResultApiBody.totalMarks,
    passingMarks: quizResultApiBody.passingMarks,
    results: convertIstudentInResultApiResponsetoIstudentInResult(
      quizResultApiBody.results
    ),
    totalPass: quizResultApiBody.totalPass,
    totalFail: quizResultApiBody.totalFail,
    totalNotAttempted: quizResultApiBody.totalNotAttempted,
    totalAwaited: quizResultApiBody.totalAwaited,
    currentStudentResult:
      quizResultApiBody.currentStudentQuizResultResponseWithRank,
  };
};

export const convertIstudentInResultApiResponsetoIstudentInResult = (
  studentInResultApiBody: IstudentInResultApiResponse[]
): IstudentInResult[] => {
  return studentInResultApiBody.map((student) => {
    return {
      studentId: student.studentId,
      studentName: student.studentName,
      imageUrl: student.imageUrl,
      score: student.score,
      status: student.status,
      duration: student.duration,
    };
  });
};

export const convertIChapterFromApitoIChapter = (
  apiChaptersBody: IChapterFromApi[]
): IChapter[] => {
  return apiChaptersBody.map((chapterBody) => {
    return {
      id: chapterBody.id,
      name: chapterBody.name,
      quizzes: ConvertQuizResponse(chapterBody.quizResponses),
      resources: chapterBody.resourceResponses,
      isCompleted: chapterBody.isCompleted,
      progress: chapterBody.progress,
    };
  });
};

export function convertTrainerCourseToICourse(
  course: ICourseOfTrainer
): ICourse {
  return {
    id: course.id,
    name: course.name,
    createdAt: course.createdAt,
    updatedAt: course.updatedAt,
    imageUrl: course.imageUrl,
    createdBy: {} as ICollaborator,
    isFavourite: false,
    isAccessible: true,
  };
}

export function convertTrainerPathToIPath(
  path: IPathOfTrainer
): IEnrolledPathResponse {
  return {
    pathId: path.id,
    pathName: path.name,
    imageUrl: path.imageUrl,
    createdAt: path.createdAt,
    updatedAt: path.updatedAt,
    noOfCourses: path.noOfCourses,
    enrolledAt: "",
    completedAt: "",
    isAccessible: true,
    progress: 0,
  };
}

export const convertICourseinPathResponsetoICourseAdmin = (
  apiBody: ICourseInPathResponse
): ICourseAdmin => {
  return {
    courseId: apiBody.id,
    courseName: apiBody.name,
    imageUrl: apiBody.imageUrl,
    about: apiBody.about,
    createdBy: apiBody.createdBy,
    createdAt: apiBody.createdAt,
    isAccessible: apiBody.isAccessible ?? true,
  };
};
