import React from "react";
import { Link } from "react-router-dom";
import { type IPath } from "../../lib/models/Path";
import Placeholder from "./../../assets/icons/light/placeholder_bg.svg";
import "./style.scss";

interface IPathProp {
  path: IPath;
}

function PathCard(props: IPathProp): React.ReactElement {
  const { id, name, imageUrl = "", noOfCourses } = props.path;
  return (
    <div className="PathCard">
      <div className="path-img">
        <Link className="card-link" to={`/path/${id}`}>
          <img
            src={imageUrl}
            id="redirect-link"
            alt="Path Pic"
            referrerPolicy="no-referrer"
            onError={(e) => {
              (e.target as HTMLInputElement).onerror = null;
              (e.target as HTMLInputElement).src = Placeholder;
            }}
          />
        </Link>
      </div>
      <div className="path-details">
        <div className="name">
          <Link className="card-link" to={`/path/${id}`}>
            {name}
          </Link>
        </div>
        <div className="description"> {noOfCourses} Courses</div>
      </div>
    </div>
  );
}

export default PathCard;
